import { useBoolean } from '@chakra-ui/react'
import axios from 'axios'
import config from '@/config'
import useUser from '@/hooks/useUser'
import { useCallback, useEffect, useState } from 'react'

export interface Name {
  fullName: string
  givenName: string
  familyName: string
}

export interface Geo {
  city: string
  state: string
  stateCode?: unknown
  country: string
  countryCode: string
  lat: number
  lng: number
}

export interface Employment {
  domain: string
  name: string
  title: string
  role: string
  subRole: string
  seniority: string
}

export interface Facebook {
  handle?: unknown
}

export interface Github {
  handle: string
  id: number
  avatar: string
  company: string
  blog: string
  followers: number
  following: number
}

export interface Twitter {
  handle: string
  id: number
  bio: string
  followers: number
  following: number
  statuses: number
  favorites: number
  location: string
  site: string
  avatar: string
}

export interface Linkedin {
  handle: string
}

export interface Googleplus {
  handle?: unknown
}

export interface Avatar {
  url: string
  type: string
}

export interface Gravatar {
  handle: string
  urls: unknown[]
  avatar: string
  avatars: Avatar[]
}

export interface RootPersonObject {
  id: string
  name: Name
  email: string
  location: string
  timeZone: string
  utcOffset: number
  geo: Geo
  bio: string
  site: string
  avatar: string
  employment: Employment
  facebook: Facebook
  github: Github
  twitter: Twitter
  linkedin: Linkedin
  googleplus: Googleplus
  gravatar: Gravatar
  fuzzy: boolean
  emailProvider: boolean
  indexedAt: Date
}

export interface ZohoType {
  zoho_deal_id: string
  zoho_org_id: string
  zoho_contact_id: string
}
export interface BackendType {
  organization_id: string
  oauth_user_id: string
  serial: string
  contacts: number
  random_password: string
}
export interface StripeType {
  stripe_subscription_id: string
  stripe_customer_id: string
}

export type SelfServiceSteps = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export interface SelfServiceUpdateRequestType {
  id: string
  email: string
  venue_name: string | ''
  google_place: google.maps.places.PlaceResult | object //store as JSON blob in dyno
  company_name: string | ''
  website: string | ''
  name: string | ''
  step: SelfServiceSteps
  backend: BackendType
}
export interface SelfServiceConfigType extends SelfServiceUpdateRequestType {
  clearbit: RootPersonObject | object //store as JSON blob in dyno
  zoho: ZohoType | null
  //  backend: BackendType | null
  stripe: StripeType
  email_confirmed: boolean
  first: string
  last: string
}

export interface SelfServiceConfigFromDynamo {
  pk: string //email
  sk: string // id
  venue_name: string | ''
  google_place: google.maps.places.PlaceResult | object //store as JSON blob in dyno
  company_name: string | ''
  website: string | ''
  name: string | ''
  step: SelfServiceSteps
  backend: BackendType
  clearbit: RootPersonObject | object //store as JSON blob in dyno
  zoho: ZohoType | null
  //  backend: BackendType | null
  stripe: StripeType
  email_confirmed: boolean
  first: string
  last: string
}

const useSelfServe = () => {
  const user = useUser()
  const [isLoading, setIsLoading] = useBoolean()
  const [error, setError] = useState(null)
  const [s, setS] = useState<SelfServiceConfigFromDynamo | null>(null)
  const getSelfData = useCallback(async () => {
    if (!user) return
    setIsLoading.on()
    try {
      const response = await axios.get<SelfServiceConfigFromDynamo>(
        `${config.url.selfservice}/email`,
        {
          params: {
            email: user.email,
          },
        }
      )
      setS(response.data)
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading.off()
    }
  }, [setIsLoading, user])

  useEffect(() => {
    getSelfData()
  }, [getSelfData])

  const update = useCallback(
    async (toUpdate: Partial<SelfServiceConfigFromDynamo>) => {
      try {
        const response = await axios.put<SelfServiceConfigFromDynamo>(
          `${config.url.selfservice}/steps`,
          { ...s, ...toUpdate }
        )
        setS(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    [s]
  )

  useEffect(() => {
    if (!s) return

    if (s.step <= 4) {
      update({ step: 4 })
    }
  }, [s, update])

  return {
    data: s,
    isLoading,
    error,
    update,
  }
}

export default useSelfServe
