import { CheckIcon, ChevronDownIcon } from '@heroicons/react/16/solid'
import { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { cx, Badge, TStack, cn, inputVariants } from 'tailwind-ui'
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/react'
import useGlobal from '@/hooks/useGlobal'
import inboxApi from '@/state/inbox/inbox.slice'
import Label from 'tailwind-ui/src/forms/labels/label'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'
import { InboxTagType } from '@/state/entities/inbox/inbox.types'

interface PassedProps {
  existingTags?: InboxTagType[]
  onCreate?: (v: string) => void
  label?: string
  row?: boolean
  threadId?: string
  showExisting?: boolean
}

export function ThreadTagCreateSelect({
  label,
  row,
  onCreate,
  existingTags = [],
  threadId,
  showExisting = false,
}: PassedProps) {
  const [query, setQuery] = useQueryParams({
    inbox_thread_tag: withDefault(StringParam, ''),
    tags_query: withDefault(StringParam, ''),
  })

  const [inputValue, setInputValue] = useState(query.tags_query ?? '')
  const [cursor, setCursor] = useState('')
  const [bottomRef, isInView] = useInView()
  const { orgId } = useGlobal()

  const { data: tags } = inboxApi.useGetThreadTagsQuery({
    orgId,
    query: { tags_query: inputValue, cursor },
  })

  const [deleteTag] = inboxApi.useDeleteThreadTagMutation()

  useEffect(() => {
    if (!isInView) return
    if (!tags?.next_cursor) return
    setCursor(tags.next_cursor)
  }, [isInView, tags?.next_cursor])

  return (
    <>
      {showExisting && existingTags.length > 0 ? (
        <div className="mb-2 space-y-1">
          <Label>Current tags</Label>
          <TStack
            horizontal
            className={cn(
              'gap-x-2 gap-y-1 flex-wrap max-w-xl items-start pr-2 py-0'
            )}
          >
            {existingTags.map((tag) => (
              <Badge
                key={tag.id}
                className="rounded-md font-light"
                action={() => {
                  if (!threadId) return
                  deleteTag({ orgId, tagId: tag.id, threadId })
                }}
              >
                {tag.name}
              </Badge>
            ))}
          </TStack>
        </div>
      ) : null}
      <Combobox
        as="div"
        value={inputValue}
        onChange={(value: string) => {
          if (onCreate && value) {
            onCreate(value)
            setQuery({ tags_query: '' })
            setInputValue('')
          } else {
            setQuery({ inbox_thread_tag: value, tags_query: value })
          }
        }}
        onClose={() => {
          setQuery({ tags_query: '' })
          setInputValue('')
        }}
      >
        <div className="relative w-full">
          <ComboboxInput
            id="tag_search"
            onChange={(event) => {
              setCursor('')
              setInputValue(event.target.value)
              setQuery({ tags_query: event.target.value })
            }}
            displayValue={(v: string | null) => {
              if (!v) return inputValue
              return v
            }}
            className={cn(
              inputVariants({ variant: 'flushed' }),
              'pr-8 text-ellipsis text-wrap'
            )}
            placeholder={onCreate ? 'Add new tag...' : 'Show all'}
            autoComplete="off"
          />

          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="size-4 text-neutral-400"
              aria-hidden="true"
            />
          </ComboboxButton>
        </div>

        <ComboboxOptions
          anchor="bottom"
          transition
          portal
          className={cn(
            'mt-1',
            'w-[var(--input-width)] rounded-xl border border-black/10 dark:border-white/10 bg-white/90 dark:bg-black/90 p-1 [--anchor-gap:var(--spacing-1)] empty:invisible',
            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
          )}
        >
          {/* Create new tag option */}
          {onCreate &&
            inputValue &&
            !tags?.data.map((t) => t.name).includes(inputValue) && (
              <ComboboxOption
                value={inputValue}
                className="cursor-pointer hover:bg-neutral-500 hover:text-white px-4 py-4 lg:py-2 rounded-md data-[focus]:bg-neutral-500 data-[focus]:text-white"
              >
                <div>Create tag "{inputValue}"</div>
              </ComboboxOption>
            )}

          {/* Existing tags */}
          {[{ id: '', name: '' }, ...(tags?.data ?? [])].map((item, index) => (
            <ComboboxOption
              key={index}
              className="cursor-pointer flex justify-between items-center hover:bg-neutral-500 hover:text-white px-4 py-4 lg:py-2 rounded-md data-[focus]:bg-neutral-500 data-[focus]:text-white"
              value={item.name}
              disabled={existingTags.map((t) => t.name).includes(item.name)}
            >
              {({ active, selected, disabled }) => (
                <>
                  <span
                    className={cx(
                      'block truncate',
                      selected && 'font-semibold',
                      disabled && 'cursor-not-allowed opacity-50'
                    )}
                  >
                    {item.name || 'Show all'}
                  </span>

                  {selected ? (
                    <CheckIcon className="size-4 fill-white group-data-[selected]:visible" />
                  ) : null}
                </>
              )}
            </ComboboxOption>
          ))}
          {tags?.next_cursor ? (
            <>
              <ComboboxOption
                className="cursor-pointer hover:bg-neutral-500 hover:text-white px-4 py-4 lg:py-2 rounded-md data-[focus]:bg-neutral-500 data-[focus]:text-white"
                key="cursor-pagination"
                value="cursor-pagination"
              >
                Show more
              </ComboboxOption>
              <div ref={bottomRef} />
            </>
          ) : null}
        </ComboboxOptions>
      </Combobox>
    </>
  )
}
