import {
  Box,
  Divider,
  HStack,
  Stack,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'

interface FieldGroupProps extends StackProps {
  title?: string
  description?: string | ReactElement
  extra?: ReactElement
  isOnlyColumn?: boolean
  wrapTitle?: boolean
}

const FieldGroup: FunctionComponent<PropsWithChildren<FieldGroupProps>> = (
  props
) => {
  const {
    title,
    description,
    children,
    extra,
    isOnlyColumn,
    wrapTitle = false,
    ...flexProps
  } = props
  return (
    <Stack
      className="field-group"
      direction={{ base: 'column', md: isOnlyColumn ? 'column' : 'row' }}
      py={6}
      spacing="6"
      {...flexProps}
    >
      <Box minW="3xs" w="full" flex="1">
        {title && (
          <HStack justify="space-between">
            <Heading>{title}</Heading>

            {extra}
          </HStack>
        )}
        {description && (
          <Description className="text-neutral-800 dark:text-neutral-300 text-sm">
            {description}
          </Description>
        )}
      </Box>
      <VStack width="full" spacing="3" flex="2">
        {children}
      </VStack>
    </Stack>
  )
}

export default FieldGroup
