import {
  IconButton,
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Text,
  Icon,
} from '@chakra-ui/react'
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/16/solid'
import { format, formatDistance, roundToNearestMinutes } from 'date-fns'
import { type FunctionComponent, useMemo } from 'react'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import {
  type EventableOutgoingEmailType,
  type InboxThreadEventEventableMessageType,
} from '@/state/entities/inbox/inbox.types'
import { useAppDispatch } from '@/state/helpers/useApp'
import { usePusherSubscription } from '@/utils/pusher'
import inboxApi, { dateParse } from '@/state/inbox/inbox.slice'
import { ChatAvatar, ThreadPopover } from './common'
import { cn, Icn, SIconButton } from 'tailwind-ui'

const formatCleanTime = (date: string | Date = '') => {
  if (!date) return 'never'
  return `${formatDistance(dateParse(date), new Date())} ago`
}

const BlueCheckTime: FunctionComponent<{
  label: string
  date: string | Date | null
}> = ({ label, date }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="text-sm ">{`${label} at ${formatCleanTime(date)}`}</div>
      <Icn
        size="sm"
        className={cn({
          'text-stampede-500': date,
          'text-bg-muted': !date,
        })}
        icon={CheckIcon}
      />
    </div>
  )
}

const BlueCheckStack: FunctionComponent<{
  item: EventableOutgoingEmailType
}> = ({ item }) => {
  return (
    <Stack key={item.id} spacing={1} pr={2}>
      <Text fontWeight="bold">
        {`${item.to_name ?? 'Unknown'} <${item.to_email}>`}
      </Text>
      <BlueCheckTime date={item.sent_at} label="Sent" />
      <BlueCheckTime date={item.delivered_at} label="Delivered" />
      <BlueCheckTime date={item.first_opened_at} label="First seen" />
    </Stack>
  )
}

const ThreadMessageBox: FunctionComponent<{
  event: InboxThreadEventEventableMessageType
  threadId: string
  eventId: string
}> = ({ event, threadId, eventId }) => {
  const dispatch = useAppDispatch()
  const hasSentToAll = useMemo(() => {
    if (event.outgoing_emails.length === 0) return false

    return (
      event.outgoing_emails.filter((item) => Boolean(item.sent_at)).length ===
      event.outgoing_emails.length
    )
  }, [event.outgoing_emails])

  const hasDeliveredToAll = useMemo(() => {
    if (event.outgoing_emails.length === 0) return false

    return (
      event.outgoing_emails.filter((item) => Boolean(item.delivered_at))
        .length === event.outgoing_emails.length
    )
  }, [event.outgoing_emails])

  const hasSeenToAll = useMemo(() => {
    if (event.outgoing_emails.length === 0) return false
    return (
      event.outgoing_emails.filter((item) => Boolean(item.first_opened_at))
        .length === event.outgoing_emails.length
    )
  }, [event.outgoing_emails])

  const hasSeenPartial = useMemo(() => {
    if (event.outgoing_emails.length === 0) return false
    return (
      event.outgoing_emails.filter((item) => Boolean(item.first_opened_at))
        .length >= 1
    )
  }, [event.outgoing_emails])

  usePusherSubscription<{ id: string; thread_id: string }>(
    `inbox.thread.${threadId}.thread_event.${eventId}.updated`,
    ({ id, thread_id }) => {
      dispatch(
        inboxApi.util.invalidateTags([{ type: 'thread_events', id: thread_id }])
      )
    }
  )

  return (
    <div className="flex items-end justify-end ml-12 group">
      <div>
        <div
          className="relative w-auto inline-flex w-fit justify-end ml-auto py-1 px-3 bg-[#147efb] rounded-2xl"
          id="content"
        >
          <ThreadPopover event={event}>
            <SIconButton
              icon={InformationCircleIcon}
              size="sm"
              className="absolute opacity-0 left-0 group-hover:opacity-100 transition-all duration-250 group-hover:-left-16"
              aria-label="info"
            />
          </ThreadPopover>
          <div
            className="text-white"
            dangerouslySetInnerHTML={{ __html: event.description }}
          />
        </div>
        <Popover trigger="hover">
          <PopoverTrigger>
            <div className="flex justify-end items-center space-x-0">
              <div
                className="text-xs text-neutral-600 text-right pr-0.5"
                id="time"
              >
                {format(
                  roundToNearestMinutes(event.created_at, { nearestTo: 10 }),
                  TimeFormats.FourHourApple
                )}
              </div>
              <Icn
                size="xs"
                icon={CheckIcon}
                className={`${hasSeenPartial ? 'text-stampede-500' : 'text-bg-muted'} z-10`}
              />
              {hasDeliveredToAll && hasSentToAll ? (
                <Icn
                  size="xs"
                  icon={CheckIcon}
                  className={`${hasSeenToAll ? 'text-stampede-500' : 'text-bg-muted'} relative -left-1.5`}
                />
              ) : null}
            </div>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />

              <PopoverBody pr={0}>
                <Stack fontSize="xs" divider={<StackDivider />}>
                  {event.outgoing_emails.map((item) => (
                    <BlueCheckStack key={item.id} item={item} />
                  ))}
                  {event.outgoing_emails.length === 0 && (
                    <Text>Message sending</Text>
                  )}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </div>

      <div className="min-w-8">
        <ChatAvatar
          id="avatar"
          email={event.user_email}
          name={event.user_name}
        />
      </div>
    </div>
  )
}

export default ThreadMessageBox
