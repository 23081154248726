import { format, roundToNearestMinutes } from 'date-fns'
import { type FunctionComponent } from 'react'
import { InboxThreadEventEventableIncomingFormSubmissionType } from '@/state/entities/inbox/inbox.types'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { Badge } from 'tailwind-ui'

const ThreadIncomingFormSubmissionBox: FunctionComponent<{
  event: InboxThreadEventEventableIncomingFormSubmissionType
  type: string
}> = ({ event, type }) => {
  return (
    <div className="flex flex-col gap-1 justify-start mr-12 group">
      <div>
        <Badge variant="subtle">Submission</Badge>
      </div>
      <div
        className="relative py-2 w-fit pl-3 inline-flex rounded-2xl bg-black/15 dark:bg-white/15"
        id="content"
      >
        <div className="divide-y divide-gray-200">
          {event.entries.map((entry) => (
            <div
              key={entry.question}
              className={`pr-3 flex space-x-2 justify-between items-baseline ${
                (entry.answers ?? '').length > 24 ? 'flex-col' : 'flex-row'
              }`}
            >
              <div className="font-bold capitalize">{entry.question}</div>
              <div>{entry.answers}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="ml-2 text-xs text-neutral-600" id="time">
        {format(
          roundToNearestMinutes(event.created_at, {
            nearestTo: 10,
          }),
          TimeFormats.FourHourApple
        )}
      </div>
    </div>
  )
}

export default ThreadIncomingFormSubmissionBox
