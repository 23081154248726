import { ScrollArea } from '@/common/scroll-area'
import { usePageTitle } from '@/hooks/usePageTitle'
import useWindowWidth from '@/hooks/useWindowWidth'
import { useUserConfig } from '@/state/morpheus/user-config/helpers'
import { ChevronLeftIcon } from '@heroicons/react/16/solid'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { motion, type Variants } from 'framer-motion'
import {
  Fragment,
  type ReactElement,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  matchRoutes,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Badge,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  Icn,
  iconButtonVariants,
  SIconButton,
} from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import { Breadcrumbs } from './breadcrumbs'

const tabs = [
  { name: 'My Account', href: '#', current: false },
  { name: 'Company', href: '#', current: false },
  { name: 'Team Members', href: '#', current: true },
  { name: 'Billing', href: '#', current: false },
]

export function TitleBar({
  backTo = '..',
  title,
  subtitle = '',
  alwaysShow = false,
  children,
}: {
  title: string
  backTo?: string | boolean
  alwaysShow?: boolean
  children?: ReactElement
  subtitle: string
}) {
  const navigate = useNavigate()
  return (
    <>
      <div className="lg:hidden bg-white/80 dark:bg-black/80 backdrop-blur-lg  z-30 top-0 left-0 w-full right-0 flex justify-start items-center p-2 gap-1">
        <SIconButton
          size="sm"
          variant="default"
          className="backdrop-blur-none"
          icon={ChevronLeftIcon}
          onClick={() => {
            navigate(-1)
          }}
        />

        <Breadcrumbs />

        <Badge className="text-sm font-medium" variant="subtle">
          {title}
        </Badge>
      </div>
    </>
  )
}

export interface LinkType {
  value: string
  key?: string
  hidden?: boolean
  icon?:
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
          title?: string | undefined
          titleId?: string | undefined
        } & React.RefAttributes<SVGSVGElement>
      >
    | string
  children?: LinkType[]
}

const RenderLinks = ({
  links,
  isNestedNav,
}: {
  links: LinkType[]
  isNestedNav: boolean
}) => {
  return links.map((link, index) => (
    <NavLink
      end={!!link.children}
      to={link.key ?? index}
      className={cn(
        'capitalize px-4 py-2  rounded-xl fill-gray-700 dark:fill-gray-300 text-nowrap aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10 hover:bg-black/5 dark:hover:bg-white/5',
        {
          //  ['py-2']: isTouchDevice(),
          // 'h-8': !isNestedNav,
        }
      )}
      size={isNestedNav ? 'lg' : 'base'}
      variant={isNestedNav ? 'ghost_default' : 'ghost_default'}
      key={link.key ?? index}
    >
      <div className="flex items-center gap-2">
        {typeof link.icon !== 'string' && link.icon ? (
          <div className="w-6 h-6 flex  items-center">
            <Icn icon={link.icon} />
          </div>
        ) : undefined}
        {typeof link.icon === 'string' ? (
          <div className="w-6 h-6 flex dark:bg-white/80 bg-black/80 rounded-xl p-0.5 items-center">
            <img
              className="object-cover h-full w-full rounded-md"
              src={link.icon}
              alt={link.value}
            />
          </div>
        ) : undefined}
        <span className="truncate overflow-hidden">{link.value}</span>
      </div>
    </NavLink>
  ))
}

const container = {
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
  show: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
}

const listItem: Variants = {
  hidden: {
    x: -50,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
}

function RecursiveMenuSection({ link }: { link: LinkType }) {
  if (link.children) {
    return (
      <>
        <DropdownMenuLabel> {link.value}</DropdownMenuLabel>

        <>
          {link.children.map((childLink, index) => (
            <RecursiveMenuSection key={index} link={childLink} />
          ))}
        </>
      </>
    )
  }

  return (
    <DropdownMenuItem>
      <NavLink to={link.key ?? ''} className="size-full">
        <div className="flex items-center">
          {link.icon && typeof link.icon !== 'string' ? (
            <link.icon className="size-4 dark:fill-white/30 fill-black/30 mr-2" />
          ) : null}
          {typeof link.icon === 'string' && (
            <div className="w-4 h-4 mr-2">
              <img
                className="object-cover h-full w-full rounded-md"
                src={link.icon}
              />
            </div>
          )}
          <span>{link.value}</span>
        </div>
      </NavLink>
    </DropdownMenuItem>
  )
}

// Add these animation variants near the other variants
const pageVariants: Variants = {
  hidden: { opacity: 0, x: 0, y: 20 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, transition: { duration: 0.5 } },
}

function TabbedNavLayout({
  links = [],
  root = '',
  title = '',
  subtitle = '',
  isRootOutlet = false,
  context,
  rightElement,
  rightClass,
  backTo = undefined,
  outletRequiresContext = false,
  outletClassName = undefined,
  isPadded = false,
  children,
}: {
  links: LinkType[]
  root: string
  title: string
  subtitle: string
  backTo?: string | number
  isRootOutlet?: boolean
  context?: unknown
  rightElement?: ReactElement
  rightClass?: string
  outletRequiresContext?: boolean
  outletClassName?: string | undefined
  isPadded?: boolean
  children?: ReactNode
  idMap?: Record<string, string>
}) {
  const location = useLocation()
  const { pathname } = useLocation()
  const { width } = useWindowWidth()
  const isNestedNav = useMemo(() => {
    if (width < 1024) {
      return true
    }
    const child = links.filter((item) => (item?.children ?? []).length > 0)
    if (child.length === 0) {
      return true
    }
    return true
  }, [links, width])

  const push = useNavigate()
  const item = matchRoutes(
    links.map(({ key }) => ({
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- allow
      path: `${root}/${key}`,
    })),
    location
  )

  usePageTitle(title)

  const path = useMemo(() => {
    if (!item) return ''
    const rootName = root.split('/').pop()
    const name = item[0].pathname.split('/').pop()

    if (name === rootName) {
      return links.find(({ key }) => key === '')?.value
    }
    return links.find(({ key }) => key === name)?.value ?? ''
  }, [item, links, root])

  useEffect(() => {
    if (isRootOutlet) return
    if (outletRequiresContext && !context) return
    document.body.classList.add('page-has-side-nav')

    return () => {
      document.body.classList.remove('page-has-side-nav')
    }
  }, [isRootOutlet, outletRequiresContext, context])

  const config = useUserConfig()

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const navigate = useNavigate()

  return (
    <div className="overflow-hidden   flex-1 flex flex-col min-h-full pt-0 md:pt-[50px] lg:pt-0">
      <div className="lg:hidden  bg-white/5  dark:bg-black/5 backdrop-blur-lg fixed z-30 top-0 left-0 md:left-16 w-full right-0 flex justify-start p-2 gap-1">
        <SIconButton
          size="sm"
          icon={ChevronLeftIcon}
          onClick={() => {
            navigate(-1)
          }}
        />
        <Breadcrumbs />
        <DropdownMenu>
          <DropdownMenuTrigger
            className={cn(iconButtonVariants({ size: 'sm' }), 'rounded-full')}
          >
            <span className="sr-only">Toggle menu</span>
            <EllipsisVerticalIcon className="size-5" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            {links
              .filter((it) => it.hidden !== true)
              .map((link, index, array) => (
                <Fragment key={link.key ?? index}>
                  <RecursiveMenuSection link={link} />
                </Fragment>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div
        className={cn(
          'grid grid-cols-1 max-md:pb-safe max-md:mb-1 relative overflow-y-auto md:overflow-hidden md:min-h-full transition-all duration-300',
          {
            'md:grid-cols-[220px_1fr] lg:grid-cols-[300px_1fr] 2xl:grid-cols-[480px_1fr] flex-1':
              isNestedNav && !isRootOutlet,
          }
        )}
      >
        <ScrollArea
          className={cn(
            'bg-black/5  overflow-x-hidden dark:bg-white/5',
            'z-0 right-0 w-80 max-md:hidden transform md:relative md:translate-x-0 md:w-auto transition-transform duration-300',
            {
              'translate-x-0': isMobileMenuOpen,
              'translate-x-full': !isMobileMenuOpen,
              hidden: isRootOutlet,
            }
          )}
        >
          {isMobileMenuOpen ? (
            <div
              className="fixed inset-0 bg-black/20 md:hidden z-40"
              onClick={() => {
                setIsMobileMenuOpen(false)
              }}
            />
          ) : null}

          <div
            className={cn(
              'flex flex-col  mb-6 backdrop-blur-lg  max-md:sticky xl:max-w-sm xl:ml-auto xl:w-full max-md:top-0 max-md:z-30',
              {
                'max-md:border-b': !isNestedNav,
                //'md:overflow-y-auto': isNestedNav,
                'pl-5':
                  config.action_bar.position === 'left' &&
                  !config.action_bar.is_fixed,
              }
            )}
          >
            <div
              className={cn(
                'flex justify-between items-start py-4 px-4 pr-2  '
              )}
            >
              <div>
                <motion.div
                  initial={{ x: -50 }}
                  animate={{ x: 0 }}
                  exit={{ x: -50 }}
                  transition={{ duration: 0.5 }}
                  key={`${root}_title`}
                >
                  <Heading className="truncate">{title}</Heading>
                </motion.div>
                {subtitle ? (
                  <Description className="truncate">{subtitle}</Description>
                ) : null}
              </div>
              <motion.div
                initial={{ x: 50 }}
                animate={{ x: 0 }}
                exit={{ x: 50 }}
                transition={{ duration: 0.5 }}
                key={`${root}_backbutton`}
              >
                <SIconButton
                  isRound
                  icon={ChevronLeftIcon}
                  onClick={() => {
                    if (!backTo) {
                      push(location.key ? '..' : -1)
                    } else {
                      push(backTo)
                    }
                  }}
                  variant="ghost_default"
                />
              </motion.div>
            </div>
            <nav
              className={cn(
                'flex flex-1 pb-2 justify-between  min-h-full space-y-6 px-2',
                {
                  'lg:flex-col ': isNestedNav,
                  '': !isNestedNav,
                }
              )}
              aria-label="Tabs"
            >
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                key={`${root}_nestednav_container`}
                className={cn(' flex-1 pb-6 flex flex-col w-full', {
                  'lg:flex-col gap-1': isNestedNav,
                  'gap-1': !isNestedNav,
                })}
              >
                {links
                  .filter((it) => it.hidden !== true)
                  .map((link, index) => (
                    <motion.div
                      variants={listItem}
                      key={!link?.key ? index : link.key}
                      className={cn('flex truncate lg:overflow-hidden', {
                        'md:flex-col md:w-full gap-1': isNestedNav,
                        'gap-3 items-center': !isNestedNav,
                      })}
                    >
                      {(link.children?.length ?? 0) > 0 ? (
                        <>
                          {link.key ? (
                            <NavLink
                              end={link.key === ''}
                              to={link.key}
                              className={({ isActive }) =>
                                cn(
                                  'font-semibold items-center leading-6 text-gray-800 dark:text-gray-200 hidden gap-2 px-4 mt-4 mb-2 lg:flex',
                                  {
                                    'is-active group': isActive,
                                  }
                                )
                              }
                            >
                              <span>{link.value}</span>
                              <div className="w-2 h-2 opacity-0 bg-green-500 border rounded-full group-[.is-active]:opacity-100 hidden" />
                            </NavLink>
                          ) : (
                            <p className="font-semibold items-center leading-6 text-gray-800 dark:text-gray-200 hidden gap-2 px-4 mt-6 mb-2 lg:flex">
                              {link.value}
                            </p>
                          )}

                          {RenderLinks({
                            links: link.children ?? [],
                            isNestedNav,
                          })}
                          {index <
                            links.filter((it) => it.hidden !== true).length -
                              1 && (
                            <div className="my-0.5 mx-4  h-px bg-black/5 dark:bg-white/5" />
                          )}
                        </>
                      ) : (
                        RenderLinks({ links: [link], isNestedNav })
                      )}
                    </motion.div>
                  ))}
                {rightElement ? (
                  <div className="lg:hidden flex items-center gap-2">
                    {rightElement}
                  </div>
                ) : null}
              </motion.div>
              {rightElement ? (
                <div
                  className={cn(
                    'self-center !max-lg:mt-4 max-lg:hidden',
                    rightClass
                  )}
                >
                  {rightElement}
                </div>
              ) : null}
              <p className="font-semibold capitalize hidden">{path}</p>
            </nav>
          </div>
        </ScrollArea>

        <div
          key={location.key}
          className={cn(
            'flex-1 relative md:pt-0 pt-12 md:pb-0 content-area flex flex-col overflow-x-hidden lg:overflow-y-auto z-0',
            {
              'px-0': !isPadded,
            },
            outletClassName
          )}
        >
          {children}
          <Outlet context={context} />
        </div>
      </div>
    </div>
  )
}

export default TabbedNavLayout
