import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSection,
  MenuSeparator,
} from '@headlessui/react'
import {
  ArrowPathIcon,
  ArrowRightEndOnRectangleIcon,
  LifebuoyIcon,
  MoonIcon,
  PlusCircleIcon,
  SunIcon,
} from '@heroicons/react/16/solid'
import { format, parseISO } from 'date-fns'
import { Fragment, lazy, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import {
  Avatar,
  Icn,
  Modal,
  StampedeIcon,
  StampedeWordmark,
  cn,
} from 'tailwind-ui'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import {
  orgNavigationItems,
  profileOrgNavigationItems,
} from '@/components/SideNav/menu-config'
import { usePathname } from '@/helpers/router'
import { useToggleZendesk } from '@/helpers/zendesk'
import { useColorMode } from '@/hooks/use-color-mode'
import useUser from '@/hooks/useUser'
import { defaultButtonClasses } from '@/pages/[org_id]/action-bar'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import { useUserConfig } from '@/state/morpheus/user-config/helpers'
import { gravatarImage } from '@/utils/common'
import { getColorFromString } from '@/utils/less'

const AddLocationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "add-location" */ '@/components/LocationsPage/AddLocationPage'
    )
)

function ProfileNav() {
  const [addVenueModalIsOpen, setAddVenueModalIsOpen] = useState(false)
  const [, toggleZendesk] = useToggleZendesk()
  const [isPopover, setIsPopover] = useState(false)
  const user = useUser()
  const pathname = usePathname()
  const { org_id: orgId } = useParams<{ org_id: string }>()
  const push = useNavigate()
  useEffect(() => {
    setIsPopover(false)
  }, [pathname])

  const [isDark, setIsDark] = useColorMode()

  const [add, { isLoading: isAddVenueLoading }] =
    morpheusApi.useAddVenueMutation()

  const buildDate = __DATE__
  const config = useUserConfig()

  return (
    <>
      <Modal
        title="Add a Venue"
        isOpen={addVenueModalIsOpen}
        onClose={() => {
          setAddVenueModalIsOpen(false)
        }}
      >
        <AddLocationPage
          toggleModal={() => {
            setAddVenueModalIsOpen(false)
          }}
        />
      </Modal>

      <Menu as="div" className="relative">
        {({ open }) => (
          <>
            <MenuButton
              className={cn(
                'group group-[.dragging]:pointer-events-none rounded-full  flex items-center justify-center !bg-transparent hover:bg-gray-200/50 dark:hover:bg-gray-700/50 focus:ring-0 focus:border-0 focus:outline-none ',
                defaultButtonClasses,
                {
                  active: open,
                  'size-12 w-full': config.action_bar.vertical,
                  'justify-start':
                    config.action_bar.vertical &&
                    config.action_bar.is_fixed &&
                    !config.action_bar.label,
                  'px-0.5 ':
                    config.action_bar.vertical && !config.action_bar.is_fixed,
                  'px-4 justify-start':
                    config.action_bar.is_fixed && config.action_bar.label,
                  'justify-center':
                    !config.action_bar.is_fixed || !config.action_bar.label,
                }
              )}
            >
              {config.action_bar.vertical && !config.action_bar.label ? (
                <StampedeIcon
                  size="lg"
                  className={cn(
                    'size-12 overflow-visible transition-transform fill-black/60 dark:fill-white/60 ',
                    {
                      'lg:fill-white/60 dark:lg:fill-black/60 size-10':
                        !config.action_bar.is_fixed,
                      'fill-stampede-500 dark:fill-stampede-500 ':
                        pathname.includes('organisation') ||
                        pathname.includes('account'),
                    }
                  )}
                />
              ) : (
                <StampedeWordmark
                  className={cn(
                    'h-full overflow-visible transition-transform fill-black/60 dark:fill-white/60',
                    {
                      'px-4':
                        config.action_bar.label && !config.action_bar.is_fixed,
                      'px-0 ':
                        config.action_bar.label && config.action_bar.is_fixed,
                      'lg:fill-white/60 dark:lg:fill-black/60 size-10':
                        !config.action_bar.is_fixed,
                    }
                  )}
                />
              )}
            </MenuButton>

            <MenuItems
              transition
              anchor="left end"
              className="z-50  origin-top-right p-1 space-y-1 md:rounded-xl border-black/10 dark:border-white/10 lg:border  bg-white/80 md:mb-4 dark:bg-black/80 backdrop-blur-xl w-full md:w-60  ring-0 focus:outline-none shadow-lg transition duration-300 ease-in-out [--anchor-gap:var(--spacing-1)] max-md:data-[closed]:translate-y-full  md:data-[closed]:scale-95 data-[closed]:opacity-0 !max-md:max-h-auto"
            >
              <MenuSection>
                {/* Profile Info */}
                <div className="space-y-2  pt-2">
                  {/* Avatar and User Info */}
                  <div className="items-center  flex gap-4 px-2">
                    <Avatar
                      name={`${user?.first} ${user?.last}`}
                      className="w-12 h-12 rounded-full"
                      src={gravatarImage(user?.email)}
                      style={{
                        backgroundColor: getColorFromString(user?.email),
                      }}
                    />
                    <div>
                      <div className="text-sm font-medium leading-6 font-['Montserrat']">
                        {user?.first} {user?.last}
                      </div>
                      <div className="-mt-1 truncate text-xs font-light leading-6 font-['Montserrat']">
                        {user?.email}
                      </div>
                    </div>
                  </div>

                  {/* Quick Actions */}
                  <div className="rounded-lg w-full p-1 gap-0.5 items-center bg-black/10 dark:bg-white/10  border-black/10 dark:border-white/10 border flex flex-col lg:inline-flex">
                    {profileOrgNavigationItems.map((item) => (
                      <MenuItem key={item.key} as={Fragment}>
                        <NavLink
                          to={item.key}
                          className={cn(
                            'group flex text-sm/6 w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 aria-[current=page]:bg-black/10 dark:aria-[current=page]:bg-white/10'
                          )}
                        >
                          <Icn
                            icon={item.icon}
                            size="lg"
                            className="size-4 fill-black/30 dark:fill-white/30"
                          />
                          <span>{item.title}</span>
                        </NavLink>
                      </MenuItem>
                    ))}

                    <MenuItem as={Fragment}>
                      <button
                        className={cn(
                          'group flex text-sm/6 w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10'
                        )}
                        onClick={() => {
                          setIsDark((s) => !s)
                        }}
                      >
                        <Icn
                          icon={isDark ? SunIcon : MoonIcon}
                          size="lg"
                          className="size-4 fill-black/30 dark:fill-white/30"
                        />
                        <span>{isDark ? 'Light Mode' : 'Dark Mode'}</span>
                      </button>
                    </MenuItem>
                    <MenuItem as={Fragment}>
                      <button
                        className={cn(
                          'group flex text-sm/6 w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10'
                        )}
                        onClick={async () => {
                          // Unregister all service workers before reloading
                          try {
                            if ('serviceWorker' in navigator) {
                              const registrations =
                                await navigator.serviceWorker.getRegistrations()
                              for (const registration of registrations) {
                                await registration.unregister()
                              }
                            }
                          } catch {
                          } finally {
                            window.location.reload()
                          }
                        }}
                      >
                        <Icn
                          icon={ArrowPathIcon}
                          size="lg"
                          className="size-4 fill-black/30 dark:fill-white/30"
                        />
                        <span>Reload</span>
                      </button>
                    </MenuItem>
                  </div>
                </div>
              </MenuSection>

              <MenuSection>
                {orgNavigationItems.map((item) => (
                  <MenuItem key={item.title} as={Fragment}>
                    <Link
                      className={cn(
                        'group flex text-sm/6 w-full items-center gap-2 rounded-lg py-1.5 px-3 dark:data-[focus]:bg-white/10 data-[focus]:bg-black/10 '
                      )}
                      to={item.key}
                    >
                      <Icn
                        icon={item.icon}
                        size="lg"
                        className="size-4 fill-black/30 dark:fill-white/30"
                      />
                      <span>{item.title}</span>
                    </Link>
                  </MenuItem>
                ))}
              </MenuSection>

              <MenuSeparator className="my-0.5 border dark:border-white/5 border-black/5" />

              <MenuSection>
                <div className=" w-full flex  flex-col justify-evenly">
                  <MenuItem as={Fragment}>
                    <Link
                      to="../oauth/logout"
                      className={cn(
                        'group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 dark:data-[focus]:bg-white/10 data-[focus]:bg-black/10'
                      )}
                    >
                      <ArrowRightEndOnRectangleIcon className="size-4 fill-black/30 dark:fill-white/30" />
                      <span className="text-xs font-normal">Logout</span>
                    </Link>
                  </MenuItem>

                  <MenuItem as={Fragment}>
                    <Link
                      to="https://help.stampede.ai"
                      target="_blank"
                      className={cn(
                        'group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 dark:data-[focus]:bg-white/10 data-[focus]:bg-black/10'
                      )}
                    >
                      <LifebuoyIcon className="size-4 fill-black/30 dark:fill-white/30" />
                      <span className="text-xs font-normal">Support</span>
                    </Link>
                  </MenuItem>

                  <MenuItem as={Fragment}>
                    <button
                      onClick={() => {
                        toast.promise(add({ orgId }).unwrap(), {
                          loading: `Adding venue...`,
                          success: (data) => {
                            push(`/${orgId}/venues/${data.serial}/settings`)
                            return `Venue added`
                          },
                          error: `Failed to add venue`,
                        })
                      }}
                      disabled={isAddVenueLoading}
                      className={cn(
                        'group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 dark:data-[focus]:bg-white/10 data-[focus]:bg-black/10'
                      )}
                    >
                      <PlusCircleIcon className="size-4 fill-black/30 dark:fill-white/30" />
                      <span className="text-xs font-normal">Venue</span>
                    </button>
                  </MenuItem>
                </div>
                <MenuSeparator className="my-0.5 border dark:border-white/5 border-black/5" />

                <div className="flex  w-full px-2 bg-transparent">
                  <span className="text-xs/6 opacity-70">
                    Build Time{' '}
                    {format(
                      parseISO(buildDate),
                      TimeFormats.StandardDateMonthYearSmallHHmm
                    )}
                  </span>
                </div>
              </MenuSection>
            </MenuItems>
          </>
        )}
      </Menu>
    </>
  )
}

export default ProfileNav
