import type { FunctionComponent, PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

const OnlineStatusContext = createContext(true)

export const OnlineStatusProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true)

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
    }

    const handleOffline = () => {
      setIsOnline(false)
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext)
  return store
}
