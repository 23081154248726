import { Stack, StackProps } from '@chakra-ui/react'
import { defaultServicePagination } from '@/connect-types/backend/service'
import { FunctionComponent, PropsWithChildren } from 'react'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import ItemsSkeleton, { NoItems } from './items.skeleton'
import VenueItem from './venue.item'

interface PassedProps {
  search: string
}
interface QueryOptions {
  limit: number
  search?: string
}

const initOptions: QueryOptions = {
  limit: 20,
}

const OrganisationVenues: FunctionComponent<
  PropsWithChildren<PassedProps & StackProps>
> = ({ search, ...rest }) => {
  const [cursor] = useQueryParam('cursor', withDefault(StringParam, ''))

  const { data: venues = defaultServicePagination, isLoading } =
    morpheusApi.useGetLocationsQuery({
      ...initOptions,
      cursor,
      search,
    })

  return (
    <ul>
      {isLoading && venues.data.length === 0 && (
        <ItemsSkeleton total={venues.data.length} />
      )}
      {!isLoading && venues.data.length === 0 && <NoItems />}

      {venues.data.map((item) => (
        <VenueItem key={item.id} {...item} />
      ))}
    </ul>
  )
}

export default OrganisationVenues
