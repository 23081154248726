import { Fragment, FunctionComponent, PropsWithChildren, useState } from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { InboxThreadType } from '@/state/entities/inbox/inbox.types'
import { gravatarImage } from '@/utils/common'
import { format } from 'date-fns'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { Avatar } from 'tailwind-ui'

export const ChatAvatar: FunctionComponent<{
  email: string
  name: string
  className?: string
}> = ({ name, email, className = '' }) => (
  <Avatar
    name={name}
    src={gravatarImage(email)}
    alt={name}
    cssBg=""
    textClassName="text-white/90 dark:text-black/90"
    className={`size-8 bg-black/50 dark:bg-white/50 rounded-full ${className}`}
  />
)

export const ThreadPopover: FunctionComponent<
  PropsWithChildren<{
    event: InboxThreadType['events']['data'][number]['eventable']
  }>
> = ({ children, event }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Popover
      onMouseEnter={() => {
        setIsOpen(true)
      }}
      onMouseOver={() => {
        setIsOpen(true)
      }}
      onMouseLeave={() => {
        setIsOpen(false)
      }}
      open={isOpen}
      className="relative"
      close={() => setIsOpen(false)}
    >
      <PopoverButton as={Fragment}>{children}</PopoverButton>

      <PopoverPanel
        transition
        portal
        anchor="bottom"
        className="absolute z-50 w-72 divide-y divide-white/5 rounded-xl bg-white/80 dark:bg-black/80 backdrop-blur-xl text-sm/6 border border-black/10 dark:border-white/10 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
      >
        {'user_name' in event && (
          <div className="p-2 space-y-2">
            <div className="block rounded-lg py-2 px-3">
              <p className="font-semibold ">By: {event.user_name}</p>
              <p className="">
                Date: {format(event.created_at, TimeFormats.StandardWithTime)}
              </p>
            </div>
          </div>
        )}

        {'contacts' in event && (
          <div className="p-3 space-y-3">
            <div className="block rounded-lg py-2 px-3">
              <p className="font-semibold ">From:</p>
              {event.contacts
                .filter((it) => it.type === 'from')
                .map((ite) => (
                  <div key={ite.email} className="">
                    <p className="font-semibold ">{ite.name}</p>
                    <p>{`<${ite.email}>`}</p>
                  </div>
                ))}
            </div>

            <div className="block rounded-lg py-2 px-3">
              <p className="font-semibold ">To:</p>
              {event.contacts
                .filter((it) => it.type === 'to')
                .map((ite) => (
                  <div key={ite.email} className="">
                    <p className="font-semibold ">{ite.name}</p>
                    <p>{`<${ite.email}>`}</p>
                  </div>
                ))}
            </div>

            <div className="block rounded-lg py-2 px-3">
              <p className="font-semibold ">Cc:</p>
              {event.contacts
                .filter((it) => it.type === 'cc')
                .map((ite) => (
                  <div key={ite.email} className="">
                    <p className="font-semibold ">{ite.name}</p>
                    <p>{`<${ite.email}>`}</p>
                  </div>
                ))}
            </div>

            <div className="block rounded-lg py-2 px-3">
              <p className="font-semibold ">
                Date: {format(event.created_at, TimeFormats.StandardWithTime)}
              </p>
              <p className="text-white/50">Subject: {event.subject}</p>
            </div>
          </div>
        )}
      </PopoverPanel>
    </Popover>
  )
}
