import { ArrowRightIcon, MapPinIcon } from '@heroicons/react/16/solid'
import { type FunctionComponent, type PropsWithChildren } from 'react'
import { Avatar, Icn } from 'tailwind-ui'
import { NavLink } from 'react-router-dom'
import { type SimpleVenueType } from '@/state/morpheus/types/venue'
import { generateImage } from '@/helpers/image'

const VenueItem: FunctionComponent<PropsWithChildren<SimpleVenueType>> = ({
  id,
  serial,
  alias: name,
  organization_id: organisation_id,
  branding_settings: {
    headerImage: icon,
    headerColor: header_color,
    interfaceColor,
  },
}) => {
  return (
    <li key={id}>
      <NavLink
        to={`/${organisation_id}/venues/${serial}`}
        className="w-full flex items-center rounded-xl rounded-1.5-consentric p-1 flex justify-between group text-neutral-700 dark:text-neutral-200 hover:bg-neutral-50 dark:hover:bg-neutral-800"
      >
        <div className="flex gap-4 items-center">
          <img
            name={name}
            size="sm"
            className="object-contain size-10 !rounded-xl bg-neutral-200 dark:bg-neutral-800"
            src={generateImage(icon, 'avatar')}
          />

          <span>{name || serial}</span>
        </div>
        <Icn
          icon={ArrowRightIcon}
          className="group-hover:text-stampede-500 relative group-hover:translate-x-1 transition-transform ease-in-out duration-300 text-neutral-600 dark:text-neutral-500"
        />
      </NavLink>
    </li>
  )
}

export default VenueItem
