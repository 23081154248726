import { FormControl, FormLabel } from '@chakra-ui/form-control'
import {
  Input,
  HStack,
  Spacer,
  Stack,
  StackDivider,
  Button,
  Text,
} from '@chakra-ui/react'
import { FormikProvider, useFormik } from 'formik'
import { useLocation } from 'react-router-dom'
import { object, string, array } from 'yup'
import QuillTextarea from '@/common/RichTextInput/quill'
import useGlobal from '@/hooks/useGlobal'
import type {
  InboxContextTypes,
  InboxThreadType,
} from '@/state/entities/inbox/inbox.types'
import EmailContacts from './email/contacts'
import inboxApi from '@/state/inbox/inbox.slice'
import { SButton } from 'tailwind-ui'

interface PassedProps {
  onComplete: (value: InboxThreadType) => void
  context?: {
    id: string
    type: InboxContextTypes
  }
  createWith: {
    contacts: { email: string; name: string }[]
    serial?: string
    subject: string
    content: string
  }
}

const validationSchema = object({
  subject: string().required('Subject is required'),
  description: string().required('Email content required'),
  contacts: array()
    .of(
      object().shape({
        name: string(),
        email: string()
          .required('Email is required')
          .email('Invalid email format'),
      })
    )
    .min(1, 'Needs one contact')
    .required('Required'),
})

const ThreadCreateSendMessageBox = ({
  createWith,
  onComplete,
  context,
}: PassedProps) => {
  const { pathname } = useLocation()
  const { orgId } = useGlobal()

  const [createNewThread] = inboxApi.useCreateNewEmailThreadMutation()

  /**
   * @todo
   * Upgrade needed
   */
  const formik = useFormik({
    initialValues: {
      description: createWith?.content ?? '',
      subject: createWith?.subject ?? '',
      contacts: createWith?.contacts ?? [{ name: '', email: '' }],
    },
    validationSchema,
    onSubmit: async ({ description, contacts, subject }, { resetForm }) => {
      const response = await createNewThread({
        orgId,
        data: {
          context_id: context?.id,
          context_type: context?.type,
          serial: createWith.serial,
          contacts,
          subject,
          content: description,
        },
      })
      onComplete(response.data.thread)

      resetForm()
    },
  })

  console.log(formik.values.contacts)

  return (
    <FormikProvider value={formik}>
      <Stack
        as="form"
        h="full"
        justify="space-between"
        onSubmit={formik.handleSubmit as any}
        pt={5}
        spacing={5}
      >
        <Stack divider={<StackDivider />} h="full" pl={5}>
          <FormControl isInvalid={Boolean(formik.errors.contacts)}>
            <Stack align="start" direction={{ base: 'row', md: 'row' }}>
              <FormLabel
                display="flex"
                fontSize="md"
                fontWeight="normal"
                minW="0"
                opacity={0.8}
                variant="inline"
              >
                To:
              </FormLabel>
              <EmailContacts
                contacts={formik.values.contacts}
                formName="contacts"
              />
            </Stack>
          </FormControl>

          <FormControl isInvalid={Boolean(formik.errors.subject)}>
            <Stack align="center" direction={{ base: 'row', md: 'row' }}>
              <FormLabel
                fontSize="md"
                fontWeight="normal"
                minW="0"
                opacity={0.8}
                variant="inline"
              >
                Subject:
              </FormLabel>
              <Input
                flex="1"
                placeholder=""
                variant="unstyled"
                w="full"
                h="24px"
                {...formik.getFieldProps('subject')}
              />
            </Stack>
          </FormControl>
          <FormControl h="full" isInvalid={Boolean(formik.errors.description)}>
            <QuillTextarea
              defaultValue={formik.values.description}
              onChange={(value) =>
                formik.setFieldValue('description', value.toString())
              }
              placeholder="Content of the message..."
            />
          </FormControl>
        </Stack>
        <HStack
          bg={pathname === '/inbox' && 'bg-surface'}
          borderBottomLeftRadius="xl"
          bottom={0}
          justify="space-between"
          overflow="hidden"
          position="sticky"
          px={5}
          py={5}
        >
          <Text color="red.500" fontSize="sm">
            {Object.entries(formik.errors)
              .map(([_key, value]) => String(value))
              .join(', ')}
          </Text>
          <Spacer />
          <SButton
            disabled={!formik.isValid}
            isLoading={formik.isSubmitting}
            size="lg"
            type="submit"
            variant="primary"
            className="border-0 rounded-full"
          >
            Send now
          </SButton>
        </HStack>
      </Stack>
    </FormikProvider>
  )
}

export default ThreadCreateSendMessageBox
