import { GridItem, SimpleGrid } from '@chakra-ui/react'
import useGlobal from '@/hooks/useGlobal'
import { FunctionComponent, useMemo, useState } from 'react'
import {
  InboxContextTypes,
  initThread,
} from '@/state/entities/inbox/inbox.types'
import { useAppDispatch, useAppSelector } from '@/state/helpers/useApp'
import ThreadSendMessageBox from '../send/send-box'
import ThreadItemBox from '../thread'
import inboxApi from '@/state/inbox/inbox.slice'
export interface InboxContextProps {
  context_id: string
  context_type: InboxContextTypes
  serial?: string
  content?: string
}

const InboxContext: FunctionComponent<InboxContextProps> = ({
  context_id,
  context_type,
  serial,
  content,
}) => {
  const { orgId } = useGlobal()
  const [sendType, setSendType] = useState<'messages' | 'notes'>('messages')

  const { data: contextThreads } = inboxApi.useGetInboxThreadsQuery({
    orgId,
    query: {
      context_id,
      context_type,
      serial,
    },
  })

  const selectedThread = useMemo(() => {
    if (!contextThreads)
      return {
        ...initThread,
        fake_thread: true,
      }
    return (
      contextThreads.data.find((i) => i.context_id === context_id) || {
        ...initThread,
        fake_thread: true,
      }
    )
  }, [contextThreads])

  if (!selectedThread.id) {
    return <></>
  }

  return (
    <div className="h-full relative overflow-hidden flex flex-col bg-bg-default">
      {/* Scrollable Content Area */}
      <div
        className="
        flex-1 
        overflow-y-auto 
        p-4 
        overscroll-contain 
        snap-y 
        snap-mandatory
      "
      >
        {selectedThread.id && <ThreadItemBox threadId={selectedThread.id} />}
      </div>

      {/* Fixed Send Message Box */}
      <div className="z-10">
        {selectedThread && (
          <ThreadSendMessageBox
            onChangeSendType={setSendType}
            sendType={sendType}
            threadId={selectedThread.id}
            onComplete={() => {}}
            content={content}
          />
        )}
      </div>
    </div>
  )
}

export default InboxContext
