import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { type InboxThreadEventEventableEmailType } from '@/state/entities/inbox/inbox.types'
import { getFirst } from '@/utils/common'
import { Icon, IconButton, Link, useBoolean } from '@chakra-ui/react'
import {
  ArrowDownTrayIcon,
  InformationCircleIcon,
} from '@heroicons/react/16/solid'
import { format, roundToNearestMinutes } from 'date-fns'
import { type FunctionComponent, useMemo } from 'react'
import AttachmentIcon from '../context/attachment/icon'
import { ChatAvatar, ThreadPopover } from './common'
import { Icn, SButton, SIconButton } from 'tailwind-ui'

const ThreadEmailBox: FunctionComponent<{
  event: InboxThreadEventEventableEmailType
  threadId: string
}> = ({ event }) => {
  const [showMore, setShowMore] = useBoolean()
  const from = useMemo(() => {
    return event.contacts.find((item) => item.type === 'from')
  }, [event.contacts])

  const cc = useMemo(() => {
    return event.contacts.filter((item) => item.type === 'cc')
  }, [event.contacts])

  return (
    <div className="flex gap-1 items-end justify-start">
      <ChatAvatar
        id="avatar"
        name={
          getFirst(event.contacts.filter((item) => item.type === 'from'))?.name
        }
        email={
          getFirst(event.contacts.filter((item) => item.type === 'from'))?.email
        }
      />
      <div className="w-full">
        <div className="rounded-xl w-[80%] border border-black/10 dark:border-white/10 bg-neutral-50/75 dark:bg-neutral-900/75">
          <div className="flex justify-between items-start pl-4 pr-2 py-2 space-x-3 text-sm border-b">
            <div className="space-y-0">
              <div className="font-bold text-sm">
                {from?.name} {`<${from?.email}>`}
              </div>
              <div className="text-sm">{event.subject}</div>

              {cc.length > 0 && (
                <div className="font-bold text-sm">
                  Cc:{' '}
                  {cc.map((item) => `${item.name} <${item?.email}>`).join(',')}
                </div>
              )}
            </div>

            <ThreadPopover event={event}>
              <SIconButton
                size="sm"
                aria-label="detail"
                variant="ghost_default"
                icon={InformationCircleIcon}
              />
            </ThreadPopover>
          </div>

          {event.html_body_trimmed ? (
            <div className="px-3 pt-3 bg-white text-black rounded-b-xl">
              <div
                className="w-full overflow-auto"
                dangerouslySetInnerHTML={{
                  __html: showMore ? event.html_body : event.html_body_trimmed,
                }}
              />
              <SButton
                size="sm"
                className="rounded-b-none backdrop-blur-none bg-white/50 dark:bg-black/50 rounded-t-md border-b-0"
                onClick={setShowMore.toggle}
              >
                {!showMore ? 'Show full email' : 'Show less'}
              </SButton>
            </div>
          ) : null}

          {!event.html_body_trimmed && (
            <div className="py-1 px-3">
              <div className="whitespace-pre-line">{event.text_body}</div>
            </div>
          )}
        </div>

        {event.attachments.length > 0 && (
          <div className="grid grid-cols-4 gap-4 pt-3 w-fit">
            {event.attachments.map((item) => (
              <div key={item.url} className="relative overflow-hidden group">
                <a
                  href={item.url.replace('http://', 'https://')}
                  className="absolute -bottom-8 right-1 rounded-full p-2 z-10 transition-all duration-250 group-hover:bottom-1"
                  download
                >
                  <Icn icon={ArrowDownTrayIcon} />
                </a>
                <AttachmentIcon
                  className="w-16 h-16 transition-all duration-250 group-hover:brightness-50"
                  attachment={item}
                />
              </div>
            ))}
          </div>
        )}

        <div className="text-left text-xs ml-2 text-neutral-600" id="time">
          {format(
            roundToNearestMinutes(event.created_at, {
              nearestTo: 10,
            }),
            TimeFormats.FourHourApple
          )}
        </div>
      </div>
    </div>
  )
}

export default ThreadEmailBox
