import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { createApi, retry } from '@reduxjs/toolkit/query/react'
import { type AxiosError, type AxiosRequestConfig } from 'axios'
import { toast } from 'sonner'
import config from '@/config'
import {
  type ServiceCursorPaginatedResponseType,
  ServiceErrorHandler,
} from '@/connect-types/backend/service'
import {
  type RewardVoucherCsvUploadRequestType,
  type RewardVoucherCsvUploadResponseType,
  type RewardVoucherSignedCsvUrlRequestType,
  type RewardVoucherSignedCsvUrlResponseType,
} from '@/connect-types/loyalty/reward-voucher-csv.type'
import { customAxios } from '@/utils/axiosHelper'
import { buildUrl } from '@/utils/common'
import { shouldBailout } from '../helpers'
import { type AutomationConfig } from './types/automation.type'
import { type Brandkit } from './types/brandkit.type'
import { type Gift } from './types/gift.type'
import { type InteractionHistory } from './types/interaction-history.type'
import {
  type LoyaltyScheme,
  type LoyaltyScheme_LoyaltyProfiles,
} from './types/loyalty-scheme.type'
import { type LoyaltyScheme_UserProfile } from './types/loyalty-scheme_user-profile.type'
import { type Reward } from './types/reward.type'
import { type Staff } from './types/staff.type'
import { type Stats } from './types/stats.type'
import { type User_Reward } from './types/user_reward.type'
import { type VoucherCsv } from './types/voucher-csv.type'
import { type LoyaltySchemeType } from './validations/loyalty-scheme'
import { type CreateReward, type UpdateReward } from './validations/reward'

interface InteractionSummaryStats {
  total: number
  count: number
  averagePerTransaction?: number
  averagePointsPerRedemption?: number
  averagePerReferral?: number
  averagePointsPerVoucher?: number
}

interface RewardTypeCount {
  title: string
  count: number
}

interface LoyaltySchemeInteractionStats {
  summary: {
    pointsIssued: InteractionSummaryStats
    rewardsRedeemed: InteractionSummaryStats
    referralPoints: InteractionSummaryStats
    vouchersRedeemed: InteractionSummaryStats
  }
  details: {
    rewards: {
      byType: RewardTypeCount[]
    }
    vouchers: {
      byReward: RewardTypeCount[]
    }
  }
  dateRange: {
    from: string
    to: string
  }
}
const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      let axiosUrl = baseUrl + url
      if (url.startsWith('https://')) {
        axiosUrl = url
      }

      const result = await customAxios({
        url: axiosUrl,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      console.log(err, 'error-here')

      if (shouldBailout(err)) {
        retry.fail(err)
      }

      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        !url.includes('segment') &&
        !url.includes('oauth')
      ) {
        ServiceErrorHandler(err)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const loyaltyApi = createApi({
  reducerPath: 'loyalty',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.loyalty }), {
    maxRetries: 2,
  }),
  tagTypes: [
    'Loyalty',
    'Reward',
    'User_Reward',
    'Automations',
    'LoyaltyUser',
    'Card',
  ],
  endpoints: (build) => ({
    getLoyaltySchemes: build.query<LoyaltyScheme[], { orgId: string }>({
      query: ({ orgId }) => ({
        url: `/v1/organisation/${orgId}/loyalty-schemes`,
        method: 'get',
      }),
      providesTags: (result, _error, { orgId }) => [
        { type: 'Loyalty', id: orgId },
      ],
    }),
    getLoyaltyScheme: build.query<LoyaltyScheme, { loyaltySchemeId: string }>({
      query: ({ loyaltySchemeId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    createLoyaltyScheme: build.mutation<
      LoyaltyScheme,
      { orgId: string; data: LoyaltySchemeType }
    >({
      query: ({ orgId, data }) => ({
        url: `/v1/organisation/${orgId}/loyalty-schemes`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (data, _error, { orgId }) => [
        { type: 'Loyalty', id: orgId },
      ],
    }),
    updateLoyaltyScheme: build.mutation<
      LoyaltyScheme,
      { loyaltySchemeId: string; data: LoyaltySchemeType }
    >({
      query: ({ loyaltySchemeId, data }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    getBrandkit: build.query<Brandkit, { orgId: string; brandkitId: string }>({
      query: ({ orgId, brandkitId }) => ({
        url: `/v1/organisations/${orgId}/brandkit/${brandkitId}`,
        method: 'GET',
      }),
    }),
    deleteLoyaltyScheme: build.mutation<
      LoyaltyScheme,
      { loyaltySchemeId: string }
    >({
      query: ({ loyaltySchemeId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
        { type: 'Loyalty', id: data?.orgId },
      ],
    }),
    getLoyaltySchemeUserProfiles: build.query<
      ServiceCursorPaginatedResponseType<LoyaltyScheme_UserProfile>,
      {
        loyaltySchemeId: string
        fromDate?: string
        toDate?: string
        query?: { cursor: string; limit: number; search?: string }
      }
    >({
      query: ({
        loyaltySchemeId,
        fromDate,
        toDate,
        query = {
          cursor: '',
          limit: 25,
          search: '',
        },
      }) => ({
        //TODO: Rename this endpoint as it is not right
        url: buildUrl(`/v1/loyalty-schemes/${loyaltySchemeId}/user-profiles`, {
          ...query,
          toDate,
          fromDate,
        }),
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    getLoyaltySchemeUserProfile: build.query<
      LoyaltyScheme_UserProfile,
      { loyaltySchemeId: string; loyaltySchemeUserProfileId: string }
    >({
      extraOptions: {
        retry: 0,
      },
      query: ({ loyaltySchemeId, loyaltySchemeUserProfileId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/loyalty-scheme-user-profiles/${loyaltySchemeUserProfileId}`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeUserProfileId }) => [
        { type: 'LoyaltyUser', id: loyaltySchemeUserProfileId },
      ],
    }),
    getReward: build.query<
      Reward,
      { rewardId: string; loyaltySchemeId: string }
    >({
      query: ({ rewardId, loyaltySchemeId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/rewards/${rewardId}`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId, rewardId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
        { type: 'Reward', id: rewardId },
      ],
    }),
    getRewards: build.query<
      ServiceCursorPaginatedResponseType<Reward>,
      {
        loyaltySchemeId: string
        fromDate?: string
        toDate?: string
        query?: {
          cursor: string
          limit: number
          search?: string
          archived?: boolean
        }
      }
    >({
      query: ({
        loyaltySchemeId,
        fromDate,
        toDate,
        query = {
          cursor: '',
          limit: 25,
          search: '',
          archived: false,
        },
      }) => ({
        url: buildUrl(`/v1/loyalty-schemes/${loyaltySchemeId}/rewards`, {
          ...query,
          toDate,
          fromDate,
        }),
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    createReward: build.mutation<
      Reward,
      { loyaltySchemeId: string; data: CreateReward }
    >({
      query: ({ loyaltySchemeId, data }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/rewards`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    deleteReward: build.mutation<
      Reward,
      { loyaltySchemeId: string; rewardId: string }
    >({
      query: ({ loyaltySchemeId, rewardId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/rewards/${rewardId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    updateReward: build.mutation<
      Reward,
      { loyaltySchemeId: string; rewardId: string; data: UpdateReward }
    >({
      query: ({ loyaltySchemeId, rewardId, data }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/rewards/${rewardId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId, rewardId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
        { type: 'Reward', id: rewardId },
      ],
    }),
    getInteractionHistory: build.query<
      ServiceCursorPaginatedResponseType<InteractionHistory>,
      {
        loyaltySchemeId: string
        fromDate?: string
        toDate?: string
        query?: {
          cursor: string
          limit: number
          loyaltySchemeUserProfileId?: string | null
        }
      }
    >({
      query: ({
        loyaltySchemeId,
        fromDate,
        toDate,
        query = {
          cursor: '',
          limit: 25,
          loyaltySchemeUserProfileId: null,
        },
      }) => ({
        url: buildUrl(
          `/v1/loyalty-schemes/${loyaltySchemeId}/interaction-history`,
          { ...query, toDate, fromDate }
        ),
        method: 'GET',
      }),
    }),
    getUserReward: build.query<
      User_Reward,
      { loyaltySchemeId: string; userRewardId: string }
    >({
      query: ({ loyaltySchemeId, userRewardId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/user-rewards/${userRewardId}`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId, userRewardId }) => [
        { type: 'User_Reward', id: userRewardId },
      ],
    }),
    getUserRewardByQrCodeData: build.query<
      User_Reward,
      { loyaltySchemeId: string; encryptedQrCodeData: string }
    >({
      query: ({ loyaltySchemeId, encryptedQrCodeData }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/rewards/qr-code-data/${encryptedQrCodeData}`,
        method: 'get',
      }),
      /*
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
      */
    }),
    redeemRewardVia: build.query<
      User_Reward,
      {
        loyaltySchemeId: string
        encryptedQrCodeData: string
        serialId: string | null
        organisationId: string
      }
    >({
      keepUnusedDataFor: 0,
      query: ({
        loyaltySchemeId,
        encryptedQrCodeData,
        serialId,
        organisationId,
      }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/reward/redeem`,
        method: 'post',
        data: { encryptedQrCodeData, serialId, organisationId },
      }),

      transformErrorResponse(error: any) {
        const errorMessage =
          error?.response?.data?.message || 'Failed to redeem reward'
        // toast.error(errorMessage)
        return errorMessage
      },
    }),
    redeemReward: build.mutation<
      User_Reward,
      {
        loyaltySchemeId: string
        encryptedQrCodeData: string
        serialId: string | null
        organisationId: string
      }
    >({
      query: ({
        loyaltySchemeId,
        encryptedQrCodeData,
        serialId,
        organisationId,
      }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/reward/redeem`,
        method: 'post',
        data: { encryptedQrCodeData, serialId, organisationId },
      }),
      invalidatesTags: (item) => [
        { type: 'LoyaltyUser', id: item?.loyaltySchemeUserProfileId || '' },
      ],
      transformErrorResponse(error: any) {
        const errorMessage =
          error?.response?.data?.message || 'Failed to redeem reward'
        // toast.error(errorMessage)
        return errorMessage
      },
    }),
    redeemRewards: build.mutation<
      User_Reward[],
      {
        loyaltySchemeId: string
        loyaltySchemeUserProfileId: string
        rewards: {
          rewardId: string
          quantity: number
        }[]
        organisationId: string
        serialId: string | null
      }
    >({
      query: ({
        loyaltySchemeId,
        loyaltySchemeUserProfileId,
        rewards,
        serialId,
        organisationId,
      }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/rewards/redeem`,
        method: 'POST',
        data: { rewards, loyaltySchemeUserProfileId, serialId, organisationId },
      }),
      invalidatesTags: (item, _, { loyaltySchemeUserProfileId }) => [
        { type: 'LoyaltyUser', id: loyaltySchemeUserProfileId ?? '' },
      ],

      transformErrorResponse(error: any) {
        const errorMessage =
          error?.response?.data?.message || 'Failed to redeem rewards'
        // toast.error('Failed to redeem rewards')
        return errorMessage
      },
    }),
    addPoints: build.mutation<
      LoyaltyScheme_UserProfile,
      {
        loyaltySchemeId: string
        loyaltySchemeUserProfileId: string
        points: number
        serialId: string | null
        organisationId: string
      }
    >({
      query: ({
        loyaltySchemeId,
        loyaltySchemeUserProfileId,
        points,
        serialId,
        organisationId,
      }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/loyalty-scheme-user-profiles/${loyaltySchemeUserProfileId}/add-points/${points}`,
        method: 'post',
        data: { points, serialId, organisationId },
      }),
      invalidatesTags: (
        item,
        _,
        { loyaltySchemeUserProfileId, organisationId }
      ) => [
        { type: 'LoyaltyUser', id: loyaltySchemeUserProfileId ?? '' },
        { type: 'Card', id: organisationId },
      ],

      /*
      invalidatesTags: (
        data,
        _error,
        { loyaltySchemeId, loyaltySchemeUserProfileId, organisationId }
      ) => [
          { type: 'Loyalty', id: loyaltySchemeUserProfileId },
        ],
        */

      transformErrorResponse(error: any) {
        const errorMessage =
          error?.response?.data?.message || 'Failed to add points'
        // toast.error(errorMessage)
        return errorMessage
      },
    }),
    getStaff: build.query<Staff[], { loyaltySchemeId: string }>({
      query: ({ loyaltySchemeId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/staff`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),

    getAutomations: build.query<
      AutomationConfig[],
      { loyaltySchemeId: string }
    >({
      query: ({ loyaltySchemeId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/automations`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Automations', id: loyaltySchemeId },
      ],
    }),
    modifyAutomation: build.mutation<
      AutomationConfig[],
      {
        loyaltySchemeId: string
        automation_id: string
        data: Partial<AutomationConfig>
      }
    >({
      query: ({ loyaltySchemeId, automation_id, data }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/automations/${automation_id}`,
        method: 'put',
        data,
      }),
      invalidatesTags: (result, error, { loyaltySchemeId }) => [
        { type: 'Automations', id: loyaltySchemeId },
      ],
    }),
    getBookings_SerialSchemesForUser: build.query<
      LoyaltyScheme_LoyaltyProfiles[],
      { org_id: string; serial: string; emails: string[] }
    >({
      query: ({ org_id, serial, emails }) => ({
        url: `/v1/bookings/loyalty-schemes/${org_id}/${serial}`,
        method: 'post',
        data: {
          emails,
        },
      }),
      providesTags: (result, _error, { org_id }) => [
        { type: 'Card', id: org_id },
      ],
    }),
    createRewardVoucherCsvSignedUrl: build.mutation<
      RewardVoucherSignedCsvUrlResponseType,
      RewardVoucherSignedCsvUrlRequestType
    >({
      query: ({
        orgId,
        loyaltySchemeId,
        originalFileName = new Date().toTimeString(),
        rewardIds,
      }) => ({
        method: 'POST',
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/rewards/voucher-csv-upload`,
        data: {
          originalFileName,
          organisationId: orgId,
          rewardIds,
        },
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    createRewardVoucherCsvUpload: build.mutation<
      RewardVoucherCsvUploadResponseType,
      RewardVoucherCsvUploadRequestType
    >({
      query: ({ url, data }) => {
        console.log({ url, data })
        return {
          method: 'put',
          url,
          headers: {
            'Content-Type': 'text/csv',
          },
          timeout: 1000 * 60,
          data,
        }
      },
      invalidatesTags: (data, _error, { orgId }) => [
        { type: 'Loyalty', id: orgId },
      ],
      transformResponse(data: RewardVoucherCsvUploadResponseType) {
        toast.success('Csv uploaded for processing')
        return data
      },
      transformErrorResponse: (error) => {
        console.log({ error })
        toast.warning('Something went wrong')
        return error
      },
    }),
    getRewardVoucherCsvs: build.query<
      ServiceCursorPaginatedResponseType<VoucherCsv>,
      {
        loyaltySchemeId: string
        query?: {
          cursor: string
          limit: number
          search?: string
          archived?: boolean
        }
      }
    >({
      query: ({
        loyaltySchemeId,
        query = {
          cursor: '',
          limit: 25,
          search: '',
          archived: false,
        },
      }) => ({
        url: buildUrl(
          `/v1/loyalty-schemes/${loyaltySchemeId}/rewards/voucher-csvs`,
          {
            ...query,
          }
        ),
        method: 'GET',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    updateRewardVoucherCsv: build.mutation<
      VoucherCsv,
      {
        loyaltySchemeId: string
        voucherCsvId: string
        state?: string
        deletedAt?: string
      }
    >({
      query: ({ loyaltySchemeId, voucherCsvId, state, deletedAt }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/rewards/voucher-csvs/${voucherCsvId}`,
        method: 'PUT',
        data: { state, deletedAt },
      }),
      invalidatesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
      transformResponse(data: VoucherCsv) {
        if (data?.deletedAt) {
          toast.success('Voucher csv deleted')
        } else {
          toast.success('Voucher csv updated')
        }
        return data
      },
      transformErrorResponse: (error) => {
        toast.warning('Something went wrong')
        return error
      },
    }),
    getStats: build.query<Stats, { loyaltySchemeId: string }>({
      query: ({ loyaltySchemeId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/stats`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    getGift: build.query<Gift, { loyaltySchemeId: string; giftId: string }>({
      query: ({ loyaltySchemeId, giftId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/gifts/${giftId}`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    getGifts: build.query<
      ServiceCursorPaginatedResponseType<Gift>,
      {
        loyaltySchemeId: string
        fromDate?: string
        toDate?: string
        query?: {
          cursor: string
          limit: number
          search?: string
          archived?: boolean
        }
      }
    >({
      query: ({
        loyaltySchemeId,
        fromDate,
        toDate,
        query = {
          cursor: '',
          limit: 25,
          search: '',
          archived: false,
        },
      }) => ({
        url: buildUrl(`/v1/loyalty-schemes/${loyaltySchemeId}/gifts`, {
          ...query,
          toDate,
          fromDate,
        }),
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    deleteGift: build.mutation<
      Gift,
      { loyaltySchemeId: string; giftId: string }
    >({
      query: ({ loyaltySchemeId, giftId }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/gifts/${giftId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    createGift: build.mutation<Gift, { loyaltySchemeId: string; data: Gift }>({
      query: ({ loyaltySchemeId, data }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/gifts`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    updateGift: build.mutation<
      Gift,
      { loyaltySchemeId: string; giftId: string; data: Gift }
    >({
      query: ({ loyaltySchemeId, giftId, data }) => ({
        url: `/v1/loyalty-schemes/${loyaltySchemeId}/gifts/${giftId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (data, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    getLoyaltySchemeStats: build.query<
      {
        totalMembers: number
        activeMembers: number
        totalPointsIssued: number
      },
      { loyaltySchemeId: string }
    >({
      query: ({ loyaltySchemeId }) => ({
        url: `/v1/reports/${loyaltySchemeId}/stats`,
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
    getLoyaltySchemeRewardStats: build.query<
      LoyaltySchemeInteractionStats,
      {
        loyaltySchemeId: string
        startDate?: string
        endDate?: string
      }
    >({
      query: ({ loyaltySchemeId, startDate, endDate }) => ({
        url: buildUrl(`/v1/reports/${loyaltySchemeId}/rewards/stats`, {
          startDate,
          endDate,
        }),
        method: 'get',
      }),
      providesTags: (result, _error, { loyaltySchemeId }) => [
        { type: 'Loyalty', id: loyaltySchemeId },
      ],
    }),
  }),
})

export default loyaltyApi
