import config from '@/config'

import Pusher, { Channel } from 'pusher-js'
import { useEffect, useMemo, useRef } from 'react'
import { getToken } from '@/utils/AuthTokenHelper'
import { useParams } from 'react-router-dom'

// We store these in a cache object so that if you call this function
// multiple times with the same `orgId`, it returns the same Pusher instance.
const pusherClients: Record<string, Pusher> = {}

function createPusherClient(orgId: string) {
  return new Pusher(config.sockets.pusher_client_key, {
    channelAuthorization: {
      transport: 'ajax',
      endpoint: `${config.url.morpheus}/organisations/${orgId}/pusher/auth`,
      headers: {
        Authorization: `Bearer ${getToken()?.accessToken}`,
      },
    },
    cluster: 'eu',
  })
}

export const usePusherClient = (orgId: string): Pusher => {
  // Use useMemo to create (and store) the same instance for this orgId
  return useMemo(() => {
    if (!orgId) return null as unknown as Pusher

    // If we already have a client for this orgId, return it
    if (pusherClients[orgId]) {
      return pusherClients[orgId]
    }
    // Otherwise, create a new one and cache it
    const client = createPusherClient(orgId)
    pusherClients[orgId] = client
    return client
  }, [orgId])
}
// We'll keep a single channel reference here so multiple
// subscriptions can share it without re-subscribing.
let cachedChannel: Channel | null = null

const topicSubscriptions: Record<string, number> = {}

/**
 * Subscribes to a single topic (event name) on the "private-{orgId}" channel.
 * You only need to pass the topic and a callback to handle the incoming data.
 */
export function usePusherSubscription<T extends object>(
  topic: string,
  callback: (data: T) => void
) {
  const { org_id } = useParams<{ org_id: string }>()
  const pusherClient = usePusherClient(org_id!)

  // Keep a mutable ref to the callback so we don't have to rebind the event handler every render
  const callbackRef = useRef(callback)
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (!pusherClient || !org_id || !topic) return

    // If we haven't subscribed to the channel yet, do it now
    if (!cachedChannel) {
      console.log('binding subscribing to channel', `private-${org_id}`)
      cachedChannel = pusherClient.subscribe(`private-${org_id}`)
    }

    // Increment subscription count for this topic
    topicSubscriptions[topic] = (topicSubscriptions[topic] || 0) + 1

    // Bind this particular topic to our current callback ref
    const handler = (data: T) => {
      callbackRef.current(data)
    }
    console.log('binding topic', topic)
    if (topicSubscriptions[topic] === 1) {
      console.log('binding topic', topic)
      cachedChannel.bind(topic, handler)
    }

    // Unbind this event handler on cleanup,
    // but do NOT unsubscribe from the channel itself (in case other hooks use it).
    return () => {
      // Decrement subscription count
      topicSubscriptions[topic]--

      // Only unbind if this was the last subscription to this topic
      if (topicSubscriptions[topic] === 0) {
        console.log('unbinding topic', topic)
        cachedChannel?.unbind(topic, handler)
        delete topicSubscriptions[topic]
      }
    }
  }, [pusherClient, org_id, topic])
}

export default createPusherClient
