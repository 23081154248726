import { type ReactNode, useCallback, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { useSegment } from '@/connect-context/tracking/useSegment'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import useUser from '@/hooks/useUser'
import { whenAvailable } from '@/utils/tracking'

const OauthContext = ({ children }: { children: ReactNode }) => {
  const user = useUser()

  const segment = useSegment()

  const { data } = morpheusApi.useGetZendeskTokenQuery(
    {},
    {
      skip: !user,
    }
  )

  useEffect(() => {
    if (!data?.token || !window.zE) return
    whenAvailable('zE', () => {
      window.zE('messenger', 'loginUser', (callback) => {
        callback(data.token)
      })
    })
  }, [data])

  const userLoaded = useCallback(() => {
    if (!user) return

    Sentry.setUser({ email: user.email, id: user.uid })
  }, [user])

  useEffect(() => {
    if (!segment || !user) return
    console.info({ segment, user }, 'segment-identify')

    segment.identify(user.uid, {
      name: `${user?.first ?? ''} ${user?.last ?? ''}`,
      email: user.email,
      createdAt: user.created,
      lastName: user.last,
      firstName: user.first,
      company: {
        name: user.company,
      },
      version: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
    })
  }, [segment, user])

  useEffect(() => {
    if (user) {
      userLoaded()
    }
  }, [user, userLoaded])

  return children
}

export default OauthContext
