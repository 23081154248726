import { Avatar } from '@chakra-ui/avatar'
import { Box, Text } from '@chakra-ui/react'
import type { AutoCompleteRefMethods } from '@choc-ui/chakra-autocomplete'
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from '@choc-ui/chakra-autocomplete'
import { FieldArray } from 'formik'
import debounce from 'lodash/debounce'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import useGlobal from '@/hooks/useGlobal'
import type { EventableContactType } from '@/state/entities/inbox/inbox.types'
import { getUserSearch } from '@/state/entities/organisation-user/organisation-user.actions'
import { useAppDispatch, useAppSelector } from '@/state/helpers/useApp'
import { gravatarImage } from '@/utils/common'
import { toast } from 'sonner'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/react'
import { cn, inputVariants, SIconButton } from 'tailwind-ui'
import { XMarkIcon } from '@heroicons/react/16/solid'

function ValidateEmail(email = '') {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
    String(email).toLowerCase()
  )
}

interface PassedProps {
  contacts: Partial<EventableContactType>[]
  formName: string
  onContactAdd?: (contact: EventableContactType) => void
  onContactRemove?: (contact: Partial<EventableContactType>) => void
}

/**
 * REQUIRES FORMIK PROVIDER CONTEXT
 */

const EmailContacts: FunctionComponent<PassedProps> = ({
  contacts,
  formName,
  onContactAdd,
  onContactRemove,
}) => {
  const dispatch = useAppDispatch()
  const { orgId } = useGlobal()
  const [query, setQuery] = useState('')
  const [selectedContacts, setSelectedContacts] =
    useState<Partial<EventableContactType>[]>(contacts)

  const users = useAppSelector(
    ({ entities }) => entities.organisation_user.user_search
  )

  const delayedSetSearch = useRef(
    debounce((value: string) => {
      dispatch(getUserSearch({ search: value, orgId }))
    }, 400)
  ).current

  useEffect(() => {
    if (!query) return
    delayedSetSearch(query)
  }, [query, delayedSetSearch])

  return (
    <FieldArray
      name={formName}
      render={(arrayHelpers) => (
        <div className="relative">
          {/* Display selected contacts as tags */}
          <div className="flex flex-wrap gap-0.5">
            {contacts.map((tag, tid) => (
              <span
                key={tid}
                className="inline-flex items-center pl-3 pr-0.5 py-0.5 gap-1 text-sm rounded-full bg-neutral-100 dark:bg-neutral-800"
              >
                <span>{tag.email}</span>
                <SIconButton
                  size="xs"
                  variant="ghost_default"
                  onClick={() => {
                    if (onContactRemove) {
                      onContactRemove(tag)
                    }
                    arrayHelpers.remove(tid)
                  }}
                  icon={XMarkIcon}
                />
              </span>
            ))}
          </div>

          <Combobox
            multiple
            value={selectedContacts}
            onChange={(newContacts) => {
              // Handle new contact selection
              const lastContact = newContacts[newContacts.length - 1]
              if (!lastContact) return

              // Handle new email input
              if (!lastContact.id) {
                const isValid = ValidateEmail(lastContact.email)
                if (!isValid) {
                  toast.warning(`Email ${lastContact.email} format is invalid`)
                  return
                }

                arrayHelpers.push({
                  email: lastContact.email,
                  name: '',
                })

                if (onContactAdd) {
                  onContactAdd({
                    email: lastContact.email,
                    name: '',
                    id: null,
                    type: 'from',
                  })
                }
                return
              }

              // Handle existing contact selection
              if (onContactAdd) {
                onContactAdd(lastContact as EventableContactType)
              }
              arrayHelpers.push(lastContact)
            }}
          >
            <div className="relative pt-1">
              <ComboboxInput
                className={cn(
                  inputVariants({ variant: 'flushed', size: 'sm' })
                )}
                onChange={(event) => setQuery(event.target.value)}
                displayValue={() => query}
                placeholder="Add email"
              />

              <ComboboxOptions className="absolute text-sm z-10 w-full min-w-[380px] mt-1 bg-white dark:bg-black/50 rounded-xl shadow-lg">
                {users.data.map((option, oid) => (
                  <ComboboxOption
                    key={`option-${oid}`}
                    value={{
                      email: option.email,
                      name: option.first
                        ? `${option.first} ${option.last}`
                        : '',
                      id: option.id,
                    }}
                    className="flex items-center px-3 py-1 cursor-pointer data-[focus]:bg-blue-100 dark:data-[focus]:bg-blue-900 rounded-lg"
                  >
                    <Avatar
                      name={`${option.first} ${option.last}`}
                      size="sm"
                      src={gravatarImage(option.email, 'tiny')}
                    />
                    <Text ml={4}>
                      {option.first} {option.last} {`<${option.email}>`}
                    </Text>
                  </ComboboxOption>
                ))}

                {/* Custom email input option */}
                {query &&
                  !users.data.find((u) => u.email === query) &&
                  ValidateEmail(query) && (
                    <ComboboxOption
                      value={{ email: query, name: '', id: null }}
                      className="px-3 py-1 cursor-pointer data-[focus]:bg-blue-100 dark:data-[focus]:bg-blue-900 rounded-lg"
                    >
                      Add: {query}
                    </ComboboxOption>
                  )}
              </ComboboxOptions>
            </div>
          </Combobox>
        </div>
      )}
    />
  )
}

export default EmailContacts
