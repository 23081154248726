import { toast } from 'sonner'

const copyToClipboard = (str: string, toastMessage: string | null = null) => {
  const el = document.createElement('textarea')

  el.value = str
  document.body.appendChild(el)
  el.select()
  navigator.clipboard.writeText(el.value)
  document.body.removeChild(el)

  toast(toastMessage ?? `${str} copied to clipboard`)
}

export { copyToClipboard }
