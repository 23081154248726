import { Fragment } from 'react'
import { useMatches } from 'react-router-dom'
import {
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  DropdownMenu,
  DropdownMenuTrigger,
  BreadcrumbEllipsis,
  DropdownMenuContent,
  DropdownMenuItem,
  BreadcrumbPage,
  Breadcrumb,
} from 'tailwind-ui'

export function Breadcrumbs() {
  const matches = useMatches()
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match))

  if (crumbs.length === 0) {
    return null
  }

  return (
    <Breadcrumb className="inline-flex  p-1 px-4 bg-black/10 dark:bg-white/10 border border-black/10 dark:border-white/10 rounded-full">
      <BreadcrumbList>
        {crumbs.map((crumb, index) => (
          <Fragment key={index}>
            <BreadcrumbItem>{crumb}</BreadcrumbItem>
            {index < crumbs.length - 1 && <BreadcrumbSeparator />}
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
