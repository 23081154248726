import { combineReducers } from '@reduxjs/toolkit'
import type { StateType } from 'typesafe-actions'
import action from './action.reducer'
import agentSmithApi from './agent-smith/agent-smith.slice'
import auth from './auth/auth.reducer'
import backendApi from './backend/backend.slice'
import billingApi from './billing/billing.slice'
import bookingApi from './booking/booking.slice'
import entities from './entities'
import global from './global/global.reducer'
import inboxApi from './inbox/inbox.slice'
import integrationsApi from './integrations/integrations.slice'
import loyaltyApi from './loyalty/loyalty.slice'
import morpheusApi from './morpheus/morpheus.slice'
import tankApi from './tank/tank.slice'
import { notificationApi } from './notification/notification.slice'
import reportsApi from './reports/reports.slice'
import serviceApi from './service/service.slice'
import wifiApi from './wifi/wifi.slice'
import zionApi from './zion/zion.slice'
import sitesApi from './sites/sites.slice'
import cypherApi from './cypher/cypher.slice'
// -----------------------------------------------------------------------------
// REDUCER

// TODO: getting a type mismatch error in VSCode, but code compiles properly
/*
const config: PersistConfig<RootState> = {
  storage,
  key: 'root',
  //transforms: [],
  whitelist: ['auth', 'global'], // We have to explicitly include the reducers to be persisted
  throttle: 250,
}

*/

const reducers = {
  auth,
  entities,
  global,
  action,
  [backendApi.reducerPath]: backendApi.reducer,
  [serviceApi.reducerPath]: serviceApi.reducer,
  [bookingApi.reducerPath]: bookingApi.reducer,
  [morpheusApi.reducerPath]: morpheusApi.reducer,
  [tankApi.reducerPath]: tankApi.reducer,
  [zionApi.reducerPath]: zionApi.reducer,
  [wifiApi.reducerPath]: wifiApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [inboxApi.reducerPath]: inboxApi.reducer,
  [billingApi.reducerPath]: billingApi.reducer,
  [loyaltyApi.reducerPath]: loyaltyApi.reducer,
  [agentSmithApi.reducerPath]: agentSmithApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [sitesApi.reducerPath]: sitesApi.reducer,
  [cypherApi.reducerPath]: cypherApi.reducer,
}
export type RootState = StateType<typeof reducers>

const rootReducer = combineReducers(reducers)

export { rootReducer }
