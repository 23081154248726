import {
  BreadcrumbLink,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'tailwind-ui'
import { NavLink, UIMatch } from 'react-router-dom'
import { lazy } from 'react'
import IndexRoute from '@/pages/index'
import RootLayout from '@/pages/layout'
import DashLayout from '@/pages/[org_id]/layout'
import type { NormalRoutesType } from './types'
import { useMenu } from './pages/[org_id]/menu-hook'
import { useVenues } from './hooks/useVenue'
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { usePathname } from './helpers/router'

const VenueSwitchCrumb = ({ data }: { data: UIMatch<unknown, unknown> }) => {
  const items = useMenu({})
  const [venues, findAlias] = useVenues()
  const pathname = usePathname()
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center gap-1 focus:outline-none">
        <BreadcrumbLink href={data.pathname}>
          {findAlias(data.params.serial)}
        </BreadcrumbLink>
        <span className="sr-only">Toggle menu</span>
        <ChevronDownIcon className="size-3" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="max-h-64 overflow-y-auto">
        {venues.map((item) => (
          <DropdownMenuItem key={item.serial}>
            <NavLink
              className="size-full"
              to={`/${item.organization_id}${pathname.replace(data.params.serial, item.serial)}`}
            >
              {item.alias || item.serial}
            </NavLink>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const VenueCrumb = ({ data }: { data: UIMatch<unknown, unknown> }) => {
  const items = useMenu({})
  const findPath = items.venue.find((item) => item.to === data.pathname)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center gap-1 focus:outline-none">
        <BreadcrumbLink href={data.pathname}>
          {findPath?.name ?? 'Venue'}
        </BreadcrumbLink>
        <span className="sr-only">Toggle menu</span>
        <ChevronDownIcon className="size-3" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {items.venue.map((item) => (
          <DropdownMenuItem key={item.to}>
            <NavLink className="size-full" to={item.to}>
              {item.name}
            </NavLink>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const newRoutesArray: NormalRoutesType[] = [
  {
    path: '/',
    component: <RootLayout />,
    children: [
      {
        index: true,
        component: <IndexRoute />,
      },
      {
        path: 'marketing/senders',
        component: lazy(() => import('@/pages/marketing/senders')),
      },
      {
        path: 'marketing/email-verification',
        component: lazy(() => import('@/pages/marketing/senders')),
      },

      {
        path: 'account-invite/:invite_id',
        component: lazy(
          () => import('@/pages/account-invite/[invite_id]/layout')
        ),
        children: [
          {
            path: 'accept',
            component: lazy(
              () => import('@/pages/account-invite/[invite_id]/accept')
            ),
          },
          {
            path: 'decline',
            component: lazy(
              () => import('@/pages/account-invite/[invite_id]/decline')
            ),
          },
        ],
      },
      {
        path: 'code',
        component: lazy(() => import('@/pages/oauth/code')),
      },
      {
        path: 'oauth',
        component: lazy(() => import('@/pages/oauth/layout')),
        children: [
          {
            path: 'logout',
            component: lazy(() => import('@/pages/oauth/logout')),
          },
          {
            path: 'code',
            component: lazy(() => import('@/pages/oauth/code')),
          },
        ],
      },
      {
        path: 'admin',
        component: lazy(() => import('@/pages/admin/layout')),
        children: [
          {
            index: true,
            component: lazy(() => import('@/pages/admin')),
          },
          {
            path: 'dpo',
            component: lazy(() => import('@/pages/admin/dpo/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/dpo/page')),
              },
            ],
          },
          {
            path: 'design-kit',
            component: lazy(() => import('@/pages/design/components')),
          },
          {
            path: 'gifting',
            component: lazy(() => import('@/pages/admin/gifting')),
          },
          {
            path: 'wi-fi',
            component: lazy(() => import('@/pages/admin/wi-fi/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/wi-fi/page')),
              },
              {
                path: 'unifi',
                component: lazy(() => import('@/pages/admin/wi-fi/unifi')),
              },
              {
                path: 'health',
                component: lazy(() => import('@/pages/admin/wi-fi/health')),
              },
              {
                path: 'snap',
                component: lazy(() => import('@/pages/admin/wi-fi/snap')),
              },
            ],
          },
          {
            path: 'cache',
            component: lazy(() => import('@/pages/admin/cache/page')),
          },
          {
            path: 'venues',
            component: lazy(() => import('@/pages/admin/venues/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/venues')),
              },
              {
                path: 'wall',
                component: lazy(() => import('@/pages/admin/venues/wall')),
              },
            ],
          },
          {
            path: 'queue',
            component: lazy(() => import('@/pages/admin/queue/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/queue')),
              },
              {
                path: 'failed',
                component: lazy(() => import('@/pages/admin/queue/failed')),
              },
              {
                path: 'slow-queries',
                component: lazy(
                  () => import('@/pages/admin/queue/slow-queries')
                ),
              },
            ],
          },
          {
            path: 'marketing',
            component: lazy(() => import('@/pages/admin/marketing/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/marketing')),
              },
              {
                path: 'email',
                component: lazy(() => import('@/pages/admin/marketing/email')),
              },
            ],
          },
          {
            path: 'interactions',
            component: lazy(() => import('@/pages/admin/interactions')),
          },
          {
            path: 'reports',
            component: lazy(() => import('@/pages/admin/reports')),
          },
          {
            path: 'create',
            component: lazy(() => import('@/pages/admin/create/layout')),
            children: [
              {
                path: 'account',
                component: lazy(() => import('@/pages/admin/create/account')),
              },
            ],
          },

          {
            path: 'organisations',
            component: lazy(() => import('@/pages/admin/organisations/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/organisations')),
              },
              {
                path: ':org_id',
                component: lazy(
                  () => import('@/pages/admin/organisations/[org_id]/layout')
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/admin/organisations/[org_id]')
                    ),
                  },

                  {
                    path: 'report',
                    component: lazy(
                      () =>
                        import('@/pages/admin/organisations/[org_id]/report')
                    ),
                  },
                  {
                    path: 'billing',
                    component: lazy(
                      () =>
                        import('@/pages/admin/organisations/[org_id]/billing')
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'customers',
            component: lazy(() => import('@/pages/admin/customers/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/customers')),
              },
              {
                path: ':id',
                component: lazy(() => import('@/pages/admin/customers/[id]')),
              },
            ],
          },
          {
            path: 'contacts',
            component: lazy(() => import('@/pages/admin/contacts/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/contacts')),
              },
            ],
          },
          {
            path: 'suppression',
            component: lazy(() => import('@/pages/admin/suppression/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/admin/suppression')),
              },
            ],
          },
        ],
      },
      {
        path: ':org_id',
        component: <DashLayout />,
        children: [
          {
            index: true,
            component: lazy(() => import('@/pages/[org_id]')),
          },
          {
            path: 'bookings',
            component: lazy(() => import('@/pages/[org_id]/bookings')),
          },
          {
            path: 'wi-fi',
            component: lazy(() => import('@/pages/[org_id]/wi-fi/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/wi-fi/page')),
              },
              {
                path: 'unifi',
                component: lazy(
                  () => import('@/pages/[org_id]/marketplace/apps/unifi')
                ),
              },
              {
                path: 'unifi/:id',
                component: lazy(
                  () => import('@/pages/[org_id]/wi-fi/unifi/[id]/page')
                ),
              },
            ],
          },
          {
            path: 'test',
            component: lazy(() => import('@/pages/[org_id]/test/layout')),
            children: [
              {
                component: lazy(() => import('@/pages/[org_id]/test')),
                index: true,
              },
              {
                component: lazy(() => import('@/pages/[org_id]/test/table')),
                path: 'table',
              },
              {
                component: lazy(() => import('@/pages/[org_id]/test/settings')),
                path: 'settings',
              },
            ],
          },
          {
            path: '2022',
            component: lazy(() => import('@/pages/[org_id]/test')),
          },
          {
            path: 'marketplace',
            component: lazy(
              () => import('@/pages/[org_id]/marketplace/layout')
            ),
            children: [
              {
                path: 'apps',
                component: lazy(
                  () => import('@/pages/[org_id]/marketplace/apps/layout')
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps')
                    ),
                  },
                  {
                    path: ':name',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/[name]')
                    ),
                  },

                  {
                    path: 'fydelia',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/fydelia')
                    ),
                  },
                  {
                    path: 'orderswift',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/marketplace/apps/orderswift')
                    ),
                  },
                  {
                    path: 'unifi',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/unifi')
                    ),
                  },
                  {
                    path: 'storekit',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/storekit')
                    ),
                  },
                  {
                    path: 'roller',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/roller')
                    ),
                  },
                  {
                    path: 'stripe',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/stripe')
                    ),
                  },
                  /*
                  {
                    path: 'mailchimp',
                    component: lazy(() =>
                        import('@/pages/[org_id]/marketplace/apps/mailchimp')
                    ),
                  },
                  */
                  {
                    path: 'paypal',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/paypal')
                    ),
                  },

                  {
                    path: 'loke',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/loke')
                    ),
                  },
                  {
                    path: 'toggle',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/toggle')
                    ),
                  },
                  {
                    path: 'resdiary',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/resdiary')
                    ),
                  },
                  {
                    path: 'hart-pms',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/hart-pms')
                    ),
                  },
                  {
                    path: 'opentable',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/marketplace/apps/opentable')
                    ),
                  },
                  {
                    path: 'access-dmn',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/marketplace/apps/access-dmn')
                    ),
                  },
                  {
                    path: 'zapier',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketplace/apps/zapier')
                    ),
                  },
                ],
              },
              {
                path: 'api-keys',
                component: lazy(
                  () => import('@/pages/[org_id]/marketplace/api-keys')
                ),
              },
            ],
          },
          {
            path: 'self-serve',
            component: lazy(() => import('@/pages/[org_id]/self-serve/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/self-serve')),
              },
              {
                path: 'csv',
                component: lazy(
                  () => import('@/pages/[org_id]/marketing/data/upload')
                ),
              },
              {
                path: 'campaign/:id',
                component: lazy(
                  () =>
                    import(
                      '@/pages/[org_id]/marketing/email/campaigns/[id]/layout'
                    )
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/marketing/email/campaigns/[id]'
                        )
                    ),
                  },
                  {
                    path: 'reports',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/marketing/email/campaigns/[id]/reports/layout'
                        )
                    ),
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/email/campaigns/[id]/reports'
                            )
                        ),
                      },
                      {
                        path: 'sent',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/email/campaigns/[id]/reports/sent'
                            )
                        ),
                      },
                      {
                        path: 'events',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/email/campaigns/[id]/reports/events'
                            )
                        ),
                      },
                      {
                        path: 'failed',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/email/campaigns/[id]/reports/failed'
                            )
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            path: 'customer',
            component: lazy(() => import('@/pages/[org_id]/customer/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/customer')),
              },
              {
                path: 'profile-redirect/:id',
                component: lazy(
                  () => import('@/pages/[org_id]/customer/profile_redirect')
                ),
              },
              {
                path: ':id',
                component: lazy(
                  () => import('@/pages/[org_id]/customer/[id]/layout')
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/customer/[id]')
                    ),
                  },
                  {
                    path: 'bookings',
                    component: lazy(
                      () => import('@/pages/[org_id]/customer/[id]/bookings')
                    ),
                  },
                  {
                    path: 'gift',
                    component: lazy(
                      () => import('@/pages/[org_id]/customer/[id]/gift')
                    ),
                  },
                  {
                    path: 'reviews',
                    component: lazy(
                      () => import('@/pages/[org_id]/customer/[id]/reviews')
                    ),
                  },
                  {
                    path: 'marketing',
                    component: lazy(
                      () => import('@/pages/[org_id]/customer/[id]/marketing')
                    ),
                  },
                  {
                    path: 'transactions',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/customer/[id]/transactions')
                    ),
                  },
                  {
                    path: 'wi-fi',
                    component: lazy(
                      () => import('@/pages/[org_id]/customer/[id]/wi-fi')
                    ),
                  },
                  {
                    path: 'activity',
                    component: lazy(
                      () => import('@/pages/[org_id]/customer/[id]/activity')
                    ),
                  },
                  {
                    path: 'email',
                    component: lazy(
                      () => import('@/pages/[org_id]/customer/[id]/email')
                    ),
                  },
                ],
              },
            ],
          },

          /**
           * ALL INBOX ROUTES HERE
           */
          {
            path: 'inbox',
            component: lazy(() => import('@/pages/[org_id]/inbox')),
          },

          /**
           * ALL MARKETING ROUTES HERE
           */

          {
            path: 'marketing',
            component: lazy(() => import('@/pages/[org_id]/marketing/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/marketing')),
              },

              /**
               * NEW ROUTING START
               */
              {
                path: 'email',
                component: lazy(
                  () => import('@/pages/[org_id]/marketing/email/layout')
                ),
                children: [
                  {
                    path: 'campaigns',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/marketing/email/campaigns/layout'
                        )
                    ),
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import('@/pages/[org_id]/marketing/email/campaigns')
                        ),
                      },
                      {
                        path: ':id',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/email/campaigns/[id]/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/email/campaigns/[id]'
                                )
                            ),
                          },
                          {
                            path: 'revisions',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/email/campaigns/[id]/revisions'
                                )
                            ),
                          },
                          {
                            path: 'quick',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/email/campaigns/[id]/quick'
                                )
                            ),
                          },
                          {
                            path: 'reports',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/email/campaigns/[id]/reports/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/marketing/email/campaigns/[id]/reports'
                                    )
                                ),
                              },
                              {
                                path: 'sent',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/marketing/email/campaigns/[id]/reports/sent'
                                    )
                                ),
                              },
                              {
                                path: 'events',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/marketing/email/campaigns/[id]/reports/events'
                                    )
                                ),
                              },
                              {
                                path: 'failed',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/marketing/email/campaigns/[id]/reports/failed'
                                    )
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'templates',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/marketing/email/templates/layout'
                        )
                    ),
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import('@/pages/[org_id]/marketing/email/templates')
                        ),
                      },
                      {
                        path: ':id',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/email/templates/[id]/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/email/templates/[id]'
                                )
                            ),
                          },
                          {
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/email/templates/[id]/revisions'
                                )
                            ),
                            path: 'revisions',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'domains',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/email/domains')
                    ),
                  },
                  {
                    path: 'audit',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/marketing/email/audit/layout')
                    ),
                    children: [
                      {
                        component: lazy(
                          () => import('@/pages/[org_id]/marketing/email/audit')
                        ),
                        index: true,
                      },
                      {
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/email/audit/[id]/layout'
                            )
                        ),
                        path: ':id',
                        children: [
                          {
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/email/audit/[id]'
                                )
                            ),
                            index: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'verification-emails',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/marketing/email/verification-emails'
                        )
                    ),
                  },
                ],
              },

              {
                component: lazy(
                  () => import('@/pages/[org_id]/marketing/foh/layout')
                ),
                path: 'foh',
                children: [
                  {
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/foh/menus')
                    ),
                    index: true,
                  },
                  {
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/foh/menus')
                    ),
                    path: 'menus',
                    children: [
                      {
                        path: ':id',
                        component: lazy(
                          () => import('@/components/menus/menu.item')
                        ),
                      },
                    ],
                  },
                  {
                    path: 'vouchers',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/marketing/foh/vouchers/layout')
                    ),
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import('@/pages/[org_id]/marketing/foh/vouchers')
                        ),
                      },
                      {
                        path: ':id',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/foh/vouchers/[id]/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/foh/vouchers/[id]'
                                )
                            ),
                          },
                          {
                            path: 'performance',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/foh/vouchers/[id]/performance'
                                )
                            ),
                          },
                          {
                            path: 'imports',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/foh/vouchers/[id]/imports'
                                )
                            ),
                          },
                          {
                            path: 'code-imports/:importId',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/foh/vouchers/[id]/code-imports/:importId/import'
                                )
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                component: lazy(
                  () => import('@/pages/[org_id]/marketing/website/layout')
                ),
                path: 'website',
                children: [
                  {
                    path: 'footprint',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/marketing/website/footprint/layout'
                        )
                    ),
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/website/footprint'
                            )
                        ),
                      },
                      {
                        path: 'customers',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/website/footprint/customers'
                            )
                        ),
                      },
                      {
                        path: 'settings',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/website/footprint/settings'
                            )
                        ),
                      },
                    ],
                  },
                  {
                    path: 'forms',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/website/forms')
                    ),
                  },
                  {
                    path: 'links',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/website/links')
                    ),
                  },
                ],
              },
              /**
               * NEW ROUTING END
               */
              {
                component: lazy(
                  () => import('@/pages/[org_id]/marketing/sms/layout')
                ),
                path: 'sms',
                children: [
                  {
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/sms/credits')
                    ),
                    path: 'credits',
                  },
                  {
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/sms/senders')
                    ),
                    path: 'senders',
                  },
                  {
                    path: 'audit',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/sms/audit')
                    ),
                    /*
                    children: [
                      {
                        path: ':id/events',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/sms/audit/'
                            )
                        ),
                      },
                    ],
                    */
                  },
                  {
                    path: 'ledger',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/sms/ledger')
                    ),
                  },
                  {
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/marketing/sms/campaigns/layout'
                        )
                    ),
                    path: 'campaigns',
                    children: [
                      {
                        component: lazy(
                          () =>
                            import('@/pages/[org_id]/marketing/sms/campaigns')
                        ),
                        index: true,
                      },
                      {
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/marketing/sms/campaigns/[id]/layout'
                            )
                        ),
                        path: ':id',
                        children: [
                          {
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/sms/campaigns/[id]'
                                )
                            ),
                            index: true,
                          },
                          {
                            path: 'reports',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/marketing/sms/campaigns/[id]/reports/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/marketing/sms/campaigns/[id]/reports'
                                    )
                                ),
                              },
                              {
                                path: 'sent',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/marketing/sms/campaigns/[id]/reports/sent'
                                    )
                                ),
                              },
                              {
                                path: 'events',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/marketing/sms/campaigns/[id]/reports/events'
                                    )
                                ),
                              },
                              {
                                path: 'failed',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/marketing/sms/campaigns/[id]/reports/failed'
                                    )
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                path: 'data',
                component: lazy(
                  () => import('@/pages/[org_id]/marketing/data/layout')
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/data/page')
                    ),
                  },
                  {
                    path: 'ai',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/data/ai')
                    ),
                  },
                  {
                    path: 'segments/all',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/marketing/data/segment/all')
                    ),
                  },
                  {
                    path: 'segments/:id',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/marketing/data/segment/[id]')
                    ),
                  },
                  {
                    path: 'upload',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/data/upload')
                    ),
                  },
                  {
                    path: 'upload/all-files',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/marketing/data/upload/all-files'
                        )
                    ),
                  },
                  {
                    path: 'ideas',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/data/ideas')
                    ),
                  },
                  {
                    path: 'suppression',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/marketing/data/suppression')
                    ),
                  },
                  {
                    path: 'meta',
                    component: lazy(
                      () => import('@/pages/[org_id]/marketing/data/meta/page')
                    ),
                  },
                ],
              },
            ],
          },
          /**
           * ALL REVIEWS ROUTES HERE
           */
          {
            path: 'reviews',
            component: lazy(() => import('@/pages/[org_id]/reviews/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/reviews')),
              },
              {
                path: 'responses',
                component: lazy(
                  () => import('@/pages/[org_id]/reviews/responses/layout')
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/reviews/responses')
                    ),
                  },
                  {
                    path: ':id',
                    component: lazy(
                      () => import('@/pages/[org_id]/reviews/responses/[id]')
                    ),
                  },
                ],
              },
              {
                component: lazy(
                  () => import('@/pages/[org_id]/reviews/pages/layout')
                ),
                path: 'pages',
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/reviews/pages')
                    ),
                  },
                  {
                    path: ':id',
                    component: lazy(
                      () => import('@/pages/[org_id]/reviews/pages/[id]')
                    ),
                  },
                ],
              },
              {
                component: lazy(() => import('@/pages/[org_id]/reviews/boost')),
                path: 'boost',
              },
              {
                component: lazy(
                  () => import('@/pages/[org_id]/reviews/reports')
                ),
                path: 'reports',
              },
            ],
          },
          /**
           * ALL VENUES ROUTES HERE
           */
          {
            path: 'venues',
            component: lazy(() => import('@/pages/[org_id]/venues/layout')),

            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/venues/index')),
              },
              {
                path: ':serial',
                component: lazy(
                  () => import('@/pages/[org_id]/venues/[serial]/layout')
                ),
                handle: {
                  crumb: (data) => {
                    return <VenueSwitchCrumb data={data} />
                  },
                },
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/venues/[serial]')
                    ),
                    handle: {
                      crumb: (data) => {
                        return <VenueCrumb data={data} />
                      },
                    },
                  },
                  {
                    path: 'in-the-building',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/venues/[serial]/in-the-building'
                        )
                    ),
                  },
                  {
                    path: 'inbox',
                    component: lazy(() => import('@/pages/[org_id]/inbox')),
                    handle: {
                      crumb: (data) => {
                        return <VenueCrumb data={data} />
                      },
                    },
                  },
                  {
                    path: 'reviews',
                    component: lazy(
                      () => import('@/pages/[org_id]/reviews/responses')
                    ),
                    handle: {
                      crumb: (data) => {
                        return <VenueCrumb data={data} />
                      },
                    },
                  },
                  {
                    path: 'brand',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/venues/[serial]/brand/layout')
                    ),
                    handle: {
                      crumb: (data) => {
                        return <VenueCrumb data={data} />
                      },
                    },
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () => import('@/pages/[org_id]/venues/[serial]/brand')
                        ),
                      },
                      {
                        path: 'wi-fi',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/branding'
                            )
                        ),
                      },
                      {
                        path: 'email',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/brand/email'
                            )
                        ),
                      },
                      {
                        path: 'reviews',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/brand/reviews'
                            )
                        ),
                      },
                      {
                        path: 'bookings',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/brand/booking'
                            )
                        ),
                      },
                      {
                        path: 'gift-cards',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/brand/gift-cards'
                            )
                        ),
                      },
                      {
                        path: 'forms',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/brand/forms'
                            )
                        ),
                      },
                      {
                        path: 'journey',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/brand/journey'
                            )
                        ),
                      },
                    ],
                  },
                  {
                    path: 'wi-fi',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/venues/[serial]/wi-fi/layout')
                    ),
                    handle: {
                      crumb: (data) => {
                        return <VenueCrumb data={data} />
                      },
                    },
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/activity'
                            )
                        ),
                      },
                      {
                        path: 'settings',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/settings'
                            )
                        ),
                      },
                      {
                        path: 'payments',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/payments'
                            )
                        ),
                      },
                      {
                        path: 'sessions',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/sessions'
                            )
                        ),
                      },
                      {
                        path: 'activity',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/activity'
                            )
                        ),
                      },
                      {
                        path: 'upsell',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/upsell/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/wi-fi/upsell'
                                )
                            ),
                          },
                          {
                            path: 'events',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/wi-fi/upsell/events'
                                )
                            ),
                          },
                          {
                            path: 'performance',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/wi-fi/upsell/performance'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'survey',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/survey'
                            )
                        ),
                      },
                      {
                        path: 'hardware',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/hardware'
                            )
                        ),
                      },
                      {
                        path: 'design',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/wi-fi/branding/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/wi-fi/branding'
                                )
                            ),
                          },
                        ],
                      },
                    ],
                  },

                  {
                    path: 'bookings',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/venues/[serial]/bookings/layout'
                        )
                    ),
                    handle: {
                      crumb: (data) => {
                        return <VenueCrumb data={data} />
                      },
                    },

                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import('@/pages/[org_id]/venues/[serial]/bookings')
                        ),
                      },
                      {
                        path: 'configuration',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/covers'
                                )
                            ),
                          },
                          {
                            path: 'covers',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/covers'
                                )
                            ),
                          },
                          {
                            path: 'intervals',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/intervals'
                                )
                            ),
                          },
                          {
                            path: 'details',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/details'
                                )
                            ),
                          },
                          {
                            path: 'user-actions',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/user_actions'
                                )
                            ),
                          },
                          {
                            path: 'options',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/options'
                                )
                            ),
                          },
                          {
                            path: 'service-hours',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/service-hours'
                                )
                            ),
                          },
                          {
                            path: 'reminders',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/reminders'
                                )
                            ),
                          },
                          {
                            path: 'payments',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/payments'
                                )
                            ),
                          },
                          {
                            path: 'messages',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/messages'
                                )
                            ),
                          },
                          {
                            path: 'tracking',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/configuration/forms/tracking'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'special-dates',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/special-dates/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/special-dates/dates'
                                )
                            ),
                          },
                          {
                            path: 'ideas',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/special-dates/ideas'
                                )
                            ),
                          },
                          {
                            path: 'archived',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/special-dates/archived'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'list',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/list'
                            )
                        ),
                      },
                      {
                        path: 'inbox',
                        component: lazy(() => import('@/pages/[org_id]/inbox')),
                      },

                      {
                        path: 'day-planner',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/day-planner/layout'
                            )
                        ),
                        children: [
                          {
                            path: 'inbox',
                            component: lazy(
                              () => import('@/pages/[org_id]/inbox')
                            ),
                          },
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/day-planner'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'floor-plans-edit',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/floor-plans-edit/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/floor-plans-edit'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'floor-plans',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/floor-plans/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/floor-plans'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'design',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/design'
                            )
                        ),
                      },

                      {
                        path: 'booking-types',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/booking-types/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/booking-types'
                                )
                            ),
                          },
                          {
                            path: 'ideas',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/booking-types/ideas'
                                )
                            ),
                          },
                          {
                            path: 'archived',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/config/[id]/settings/booking-types/archived'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'google',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/google/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/google/settings'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'automations',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/automations/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/automations/settings'
                                )
                            ),
                          },
                          {
                            path: 'stats',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/automations/stats'
                                )
                            ),
                          },
                          {
                            path: ':automation_source_id',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/automations/settings/[id]/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/bookings/automations/settings/[id]'
                                    )
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'reporting',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/reporting/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/reporting/stats'
                                )
                            ),
                          },
                          {
                            path: 'actions',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/reporting/actions'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'embed-links',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/embed-links/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/embed-links'
                                )
                            ),
                          },
                          {
                            path: 'stand-alone-booking',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/embed-links/stand-alone-booking'
                                )
                            ),
                          },
                          {
                            path: 'tablet-mode',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/embed-links/tablet-mode'
                                )
                            ),
                          },
                          {
                            path: 'embed-widget',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/embed-links/widget'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'tables',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/tables/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/tables'
                                )
                            ),
                          },
                          {
                            path: 'archived',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/tables/archived'
                                )
                            ),
                          },
                          {
                            path: 'areas',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/tables/areas/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/bookings/tables/areas'
                                    )
                                ),
                              },
                              {
                                path: 'archived',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/bookings/tables/areas/archived'
                                    )
                                ),
                              },
                            ],
                          },
                          {
                            path: 'combinations',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/tables/combinations/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/bookings/tables/combinations'
                                    )
                                ),
                              },
                              {
                                path: 'archived',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/bookings/tables/combinations/archived'
                                    )
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'combinations',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/tables/combinations/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/tables/combinations'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'areas',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/tables/areas/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/tables/areas/index'
                                )
                            ),
                          },
                          {
                            path: 'archived',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/bookings/tables/areas/archived'
                                )
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'loyalty',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/venues/[serial]/loyalty/layout'
                        )
                    ),
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import('@/pages/[org_id]/venues/[serial]/loyalty')
                        ),
                      },
                      {
                        path: ':loyalty_scheme_id',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/layout'
                            )
                        ),
                        children: [
                          {
                            index: true,
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]'
                                )
                            ),
                          },
                          {
                            path: 'service',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/service/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/service'
                                    )
                                ),
                              },
                              {
                                path: ':loyalty_scheme_user_profile_id',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/[loyalty_scheme_user_profile_id]/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/[loyalty_scheme_user_profile_id]'
                                        )
                                    ),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/go-live/layout'
                                )
                            ),
                            path: 'go-live',
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/go-live'
                                    )
                                ),
                              },
                            ],
                          },
                          {
                            path: 'customers',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers'
                                    )
                                ),
                              },
                              {
                                path: ':loyalty_scheme_user_profile_id',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/[loyalty_scheme_user_profile_id]/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/customers/[loyalty_scheme_user_profile_id]'
                                        )
                                    ),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            path: 'interaction-history',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/interaction-history/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/interaction-history'
                                    )
                                ),
                              },
                            ],
                          },
                          {
                            path: 'automations',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/automations/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/automations'
                                    )
                                ),
                              },
                            ],
                          },
                          {
                            path: 'rewards',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards'
                                    )
                                ),
                              },
                              {
                                path: 'create',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/create/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/create'
                                        )
                                    ),
                                  },
                                ],
                              },
                              {
                                path: ':reward_id',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/[reward_id]/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/[reward_id]'
                                        )
                                    ),
                                  },
                                ],
                              },
                              {
                                path: 'archived',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/archived/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/archived'
                                        )
                                    ),
                                  },
                                ],
                              },
                              {
                                path: 'vouchers',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/vouchers/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/vouchers'
                                        )
                                    ),
                                  },
                                  {
                                    path: 'upload',
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/vouchers/upload/layout'
                                        )
                                    ),
                                    children: [
                                      {
                                        index: true,
                                        component: lazy(
                                          () =>
                                            import(
                                              '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/rewards/vouchers/upload'
                                            )
                                        ),
                                      },
                                    ],
                                  },
                                  // {
                                  //   path: ":voucher_id",
                                  //   component: "[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/vouchers/[voucher_id]/layout",
                                  //   children: [
                                  //     {
                                  //       index: true,
                                  //       component: "[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/vouchers/[voucher_id]",
                                  //     },
                                  //   ],
                                  // },
                                  // {
                                  //   path: "archived",
                                  //   component: "[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/vouchers/archived/layout",
                                  //   children: [
                                  //     {
                                  //       index: true,
                                  //       component: "[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/vouchers/archived",
                                  //     },
                                  //   ],
                                  // },
                                ],
                              },
                            ],
                          },
                          {
                            path: 'gifts',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts'
                                    )
                                ),
                              },
                              {
                                path: 'archived',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/archived/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/archived'
                                        )
                                    ),
                                  },
                                ],
                              },
                              {
                                path: ':gift_id',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/[gift_id]/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/[gift_id]'
                                        )
                                    ),
                                  },
                                ],
                              },
                              {
                                path: 'create',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/create/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/gifts/create'
                                        )
                                    ),
                                  },
                                ],
                              },
                            ],
                          },

                          {
                            path: 'passes',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/passes/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/passes'
                                    )
                                ),
                              },
                            ],
                          },
                          {
                            path: 'staff',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/staff/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/staff'
                                    )
                                ),
                              },
                            ],
                          },
                          {
                            path: 'redeem',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/redeem/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/redeem'
                                    )
                                ),
                              },
                              {
                                path: ':encrypted_qr_code_data',
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/redeem/[encrypted_qr_code_data]/layout'
                                    )
                                ),
                                children: [
                                  {
                                    index: true,
                                    component: lazy(
                                      () =>
                                        import(
                                          '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/redeem'
                                        )
                                    ),
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            path: 'points',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/points/layout'
                                )
                            ),
                            children: [
                              {
                                index: true,
                                component: lazy(
                                  () =>
                                    import(
                                      '@/pages/[org_id]/venues/[serial]/loyalty/[loyalty_scheme_id]/points'
                                    )
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'settings',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/venues/[serial]/settings/layout'
                        )
                    ),
                  },

                  {
                    path: 'reports',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/venues/[serial]/reports/layout'
                        )
                    ),
                    handle: {
                      crumb: (data) => {
                        return <VenueCrumb data={data} />
                      },
                    },
                    children: [
                      {
                        path: 'interactions',
                        component: lazy(
                          () => import('@/pages/[org_id]/reports/interactions')
                        ),
                        children: [
                          {
                            path: ':id',
                            component: lazy(
                              () =>
                                import(
                                  '@/pages/[org_id]/reports/interactions/[id]'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'customers',
                        component: lazy(
                          () => import('@/pages/[org_id]/reports/customers')
                        ),
                      },
                      {
                        path: 'origin',
                        component: lazy(
                          () => import('@/pages/[org_id]/reports/origin')
                        ),
                      },
                      {
                        path: 'transactions',
                        component: lazy(
                          () => import('@/pages/[org_id]/reports/transactions')
                        ),
                      },
                      {
                        index: true,
                        component: lazy(
                          () => import('@/pages/[org_id]/reports/customers')
                        ),
                      },

                      {
                        path: 'customers',
                        component: lazy(
                          () => import('@/pages/[org_id]/reports/customers')
                        ),
                      },
                      /*
                      {
                        path: 'bandwidth',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/reports/bandwidth'
                            )
                        ),
                      },
*/
                      {
                        path: 'reviews',
                        component: lazy(
                          () => import('@/pages/[org_id]/reviews/reports')
                        ),
                      },
                      {
                        path: 'bookings',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/bookings/reporting/stats'
                            )
                        ),
                      },
                      {
                        path: 'monthly-snapshot',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/reports/monthly-snapshot/page'
                            )
                        ),
                      },
                    ],
                  },
                  {
                    path: 'site',
                    component: lazy(
                      () =>
                        import('@/pages/[org_id]/venues/[serial]/site/layout')
                    ),
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import('@/pages/[org_id]/venues/[serial]/site/page')
                        ),
                      },
                      {
                        path: 'content/:content_id',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/site/content/[content_id]/page'
                            )
                        ),
                      },
                      {
                        path: 'gallery/:gallery_id',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/venues/[serial]/site/gallery/[gallery_id]/page'
                            )
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          /**
           * LOYALTY OTHER ROUTES HERE
           * 
           *  {
    path: '/gifting',
    component: AsyncGifting,
  },
  {
    path: '/gifting/activations',
    component: AsyncGiftingActivations,
  },
  {
    path: '/gifting/reports',
    component: AsyncGiftingReports,
  },
  {
    path: '/gifting/:id',
    component: AsyncGiftingSingle,
  },

           */
          {
            path: 'loyalty',
            component: lazy(
              () => import('@/pages/[org_id]/venues/[serial]/loyalty')
            ),
          },
          {
            path: 'loyalty-legacy',
            component: lazy(() => import('@/pages/[org_id]/loyalty/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/loyalty')),
              },
              {
                path: 'new',
                component: lazy(() => import('@/pages/[org_id]/loyalty/new')),
              },
              {
                path: ':id',
                component: lazy(
                  () => import('@/pages/[org_id]/loyalty/[id]/layout')
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/loyalty/[id]')
                    ),
                  },
                  {
                    path: 'customers',
                    component: lazy(
                      () => import('@/pages/[org_id]/loyalty/[id]/customers')
                    ),
                  },
                  {
                    path: 'redemptions',
                    component: lazy(
                      () => import('@/pages/[org_id]/loyalty/[id]/redemptions')
                    ),
                  },
                  {
                    path: 'tags',
                    component: lazy(
                      () => import('@/pages/[org_id]/loyalty/[id]/tags')
                    ),
                  },
                  {
                    path: 'scheme',
                    component: lazy(
                      () => import('@/pages/[org_id]/loyalty/[id]/scheme')
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'gifting',
            component: lazy(() => import('@/pages/[org_id]/gifting/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/gifting')),
              },
              {
                path: 'scan',
                component: lazy(() => import('@/pages/[org_id]/gifting/scan')),
              },
              {
                path: 'activations',
                component: lazy(
                  () => import('@/pages/[org_id]/gifting/activations')
                ),
              },
              {
                path: 'reports',
                component: lazy(
                  () => import('@/pages/[org_id]/gifting/reports')
                ),
              },
              {
                path: 'cards',
                component: lazy(() => import('@/pages/[org_id]/gifting/cards')),
              },
              {
                path: ':id',
                component: lazy(
                  () => import('@/pages/[org_id]/gifting/[id]/layout')
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/gifting/[id]')
                    ),
                  },
                ],
              },
            ],
          },

          /**
           * Reports
           */
          {
            path: 'reports',
            component: lazy(() => import('@/pages/[org_id]/reports/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/reports')),
              },
              {
                path: 'two-point-zero',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/two-point-zero/page')
                ),
              },
              {
                path: 'multisite',

                component: lazy(
                  () => import('@/pages/[org_id]/reports/multisite')
                ),
              },
              {
                path: 'origin',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/origin')
                ),
              },
              {
                path: 'marketing-email',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/marketing/email')
                ),
              },
              {
                path: 'marketing-sms',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/marketing/sms')
                ),
              },
              {
                path: 'interactions',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/interactions')
                ),
              },
              {
                path: 'interactions/:id',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/interactions/[id]')
                ),
              },
              {
                path: 'customers',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/customers')
                ),
              },
              {
                path: 'transactions',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/transactions')
                ),
              },
              {
                path: 'my-reports',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/report-builder')
                ),
              },
              {
                path: 'monthly-retention',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/cohort/monthly')
                ),
              },
              {
                path: 'gifting',
                component: lazy(
                  () => import('@/pages/[org_id]/gifting/reports')
                ),
              },
              {
                path: 'tags',
                component: lazy(() => import('@/pages/[org_id]/reports/tags')),
              },
              {
                path: 'survey',
                component: lazy(
                  () => import('@/pages/[org_id]/reports/survey')
                ),
              },
            ],
          },

          /**
           * ALL OTHER ROUTES HERE
           */

          {
            path: 'account',
            component: lazy(() => import('@/pages/[org_id]/account/layout')),
            children: [
              {
                index: true,
                component: lazy(() => import('@/pages/[org_id]/account')),
              },
              {
                path: 'profile',
                component: lazy(
                  () => import('@/pages/[org_id]/account/profile')
                ),
              },
              {
                path: 'notifications',
                component: lazy(
                  () => import('@/pages/[org_id]/account/notifications')
                ),
              },
            ],
          },
          {
            path: 'organisation',
            component: lazy(
              () => import('@/pages/[org_id]/organisation/layout')
            ),
            children: [
              {
                index: true,
                component: <div>Organisation</div>,
              },
              {
                path: 'venues',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/venues')
                ),
              },
              {
                path: 'team',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/team')
                ),
              },
              {
                path: 'groups',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/groups')
                ),
              },
              {
                path: 'settings',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/settings')
                ),
              },
              {
                path: 'tags',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/tags')
                ),
              },
              {
                path: 'billing',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/billing')
                ),
              },
              {
                path: 'billing-2',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/billing-2')
                ),
              },

              {
                path: 'event-log',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/event-log')
                ),
              },
              {
                path: 'brand',
                component: lazy(
                  () => import('@/pages/[org_id]/organisation/brand/layout')
                ),
                children: [
                  {
                    index: true,
                    component: lazy(
                      () => import('@/pages/[org_id]/organisation/brand')
                    ),
                  },
                  {
                    path: ':id',
                    component: lazy(
                      () =>
                        import(
                          '@/pages/[org_id]/organisation/brand/[id]/layout'
                        )
                    ),
                    children: [
                      {
                        index: true,
                        component: lazy(
                          () =>
                            import('@/pages/[org_id]/organisation/brand/[id]')
                        ),
                      },
                      {
                        path: 'journey',
                        component: lazy(
                          () =>
                            import(
                              '@/pages/[org_id]/organisation/brand/[id]/journey'
                            )
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: '*',
            component: lazy(() => import('@/pages/error/404')),
          },
        ],
      },
      {
        path: '*',
        component: lazy(() => import('@/pages/error/404')),
      },
    ],
  },
  {
    path: '*',
    component: lazy(() => import('@/pages/error/404')),
  },
]
