import {
  getToken as getFbToken,
  getMessaging,
  onMessage,
} from 'firebase/messaging'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useFirebaseApp } from 'reactfire'
import { z } from 'zod'
import { toast } from 'sonner'
import { notificationApi } from '@/state/notification/notification.slice'
import { useAlertContext } from '../alerts'
import { isInStandaloneMode } from '@/utils/helpers/pwa'
import { useNavigate, useNavigation } from 'react-router-dom'

const vapidKey =
  'BCYH4lJYo9mGsIDjN07MuE3CaGrLkdhvRgWMHRfUTjpujclg2Yr-kL9Tguv-7Wfp7Vn1fwTDns-4aLtGK1RqSmU'

const useFirebaseToken = (
  setPermissionToken: (token: string | null) => void
) => {
  const app = useFirebaseApp()
  const getFirebaseToken = useCallback(async () => {
    try {
      const messaging = getMessaging(app)
      console.log('Getting token', messaging)
      const currentToken = await getFbToken(messaging, { vapidKey })
      if (currentToken) {
        setPermissionToken(currentToken)
        // You might want to send the token to your server here
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        )
        setPermissionToken(null)
      }
    } catch (error) {
      console.error('An error occurred while retrieving token. ', error)
      setPermissionToken(null)
    }
  }, [setPermissionToken])

  return getFirebaseToken
}

function AppNotifications() {
  const [hasPermission, setHasPermission] = useState(false)
  const [permissionToken, setPermissionToken] = useState<string | null>(null)
  const getFirebaseToken = useFirebaseToken(setPermissionToken)
  const [alerts, setAlerts] = useAlertContext()
  const [permissionState, setPermissionState] =
    useState<NotificationPermission>('default')
  const push = useNavigate()

  const app = useFirebaseApp()
  const requestNotificationPermission = useCallback(async () => {
    // Standard notification permission request
    try {
      const permission = await Notification.requestPermission()
      setHasPermission(permission === 'granted')
      setPermissionState(permission)
      toast.dismiss('stampede-notifications-toast')
      toast.success('Stampede notifications enabled', {
        id: 'stampede-notifications-toast-success',
      })
    } catch (error) {
      console.error('Error requesting notification permission:', error)
      setHasPermission(false)
      setPermissionState('denied')
      toast.error('Error requesting notification permission', {
        id: 'stampede-notifications-toast-success',
      })
    }
  }, [permissionToken])

  const checkPermission = useCallback(async () => {
    // Check standard notification permission
    const currentPermission = Notification.permission
    setPermissionState(currentPermission)
    setHasPermission(currentPermission === 'granted')
    if (currentPermission === 'default') {
      const toastId = 'stampede-notifications-toast'
      setTimeout(() => {
        toast.info('Stampede would like to send you real-time notifications', {
          id: toastId,
          duration: Infinity,
          action: {
            onClick: requestNotificationPermission,
            label: 'Enable',
          },
        })
      }, 20000)
    }
  }, [requestNotificationPermission])

  useLayoutEffect(() => {
    checkPermission()
  }, [checkPermission])

  useEffect(() => {
    if (!hasPermission) return
    getFirebaseToken()
  }, [getFirebaseToken, hasPermission])

  const navigation = useNavigation()
  const isTransitioning = navigation.state === 'loading'

  useEffect(() => {
    function handleSWMessage(event: any) {
      console.log('SW: from-client received SW message:', event)

      if (event.data?.type === 'NAVIGATE' && event.data?.url) {
        const url = new URL(event.data.url, window.location.origin)
        // e.g. navigate only to the path part:
        push(url.pathname + url.search + url.hash, { replace: true })
      } else if (event.data?.messageType === 'notification-clicked') {
        const url = new URL(
          event.data.notification.click_action,
          window.location.origin
        )
        push(url.pathname + url.search + url.hash, { replace: true })
      }
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', handleSWMessage)
    }

    // Cleanup on unmount
    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', handleSWMessage)
      }
    }
  }, [push])

  useEffect(() => {
    if (permissionState !== 'granted') return
    const messaging = getMessaging(app)
    const unsubscribe = onMessage(messaging, (newMessage) => {
      console.log('SW: from-server received message:', newMessage)
      const shape = z
        .object({
          notification: z.object({
            title: z.string(),
            body: z.string(),
          }),
          fcmOptions: z.object({
            link: z.string(),
          }),
          messageId: z.string(),
        })
        .safeParse(newMessage)

      if (shape.success) {
        const { notification, fcmOptions, messageId } = shape.data

        const url = new URL(fcmOptions.link, window.location.origin)

        const notificationOptions: NotificationOptions = {
          body: notification.body,
          data: { url: url.toString() },
          icon: '/pwa-512x512.png',
          // Add badge for Safari
          badge: '/pwa-512x512.png',
          // Add actions if needed
        }

        /*
        try {
          
          const notify = new Notification(
            notification.title,
            notificationOptions
          )

          notify.onclick = (event) => {
            event.preventDefault() // Prevent default behavior
            if (isInStandaloneMode()) {
              // In PWA mode, let the service worker handle navigation
              if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                  type: 'NOTIFICATION_CLICK',
                  payload: url.toString(),
                })
                push(url.pathname + url.search + url.hash, { replace: true })
              }
              return
            }

            push(url.pathname + url.search + url.hash, { replace: true })
            window.focus()
          }
        } catch (error) {
          console.error('Error showing notification:', error)
        }
*/
        // Keep existing alert and toast functionality
        setAlerts({
          itemKey: messageId,
          title: notification.title,
          type: 'info',
          buttonText: 'View',
          to: url.pathname,
          isDismissible: true,
        })

        toast.info(notification.title, {
          description: notification.body,
          action: {
            onClick: () => {
              push(url.pathname + url.search + url.hash, { replace: true })
            },
            label: 'View',
          },
        })
      } else {
        console.log('SW: from-client error', shape.error)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [setAlerts, permissionState, push])

  notificationApi.useGetBrowserTokenQuery(
    {
      token: permissionToken!,
    },
    {
      skip: !permissionToken,
    }
  )

  return <></>
}

export default AppNotifications
