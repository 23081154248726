import { OrganisationServerType } from '@/connect-types/organisations/organisations.type'
import { createAction, createAsyncAction } from 'typesafe-actions'
import {
  LinkGeneratorRequestType,
  LinkGeneratorType,
  OrganisationLabelType,
} from './organisations.types'
import { AccessVenueType } from '@/pages/[org_id]/marketplace/apps/access-dmn/access.types'
import { RollerVenuesType } from '@/pages/[org_id]/marketplace/apps/roller/roller.types'

export const organisationPushNewCustomer = createAction(
  'ORGANISATION_PUSH_NEW_CUSTOMER',
  (resolve) => (customer: OrganisationServerType) => resolve({ customer })
)

export const getOrganisationLinks = createAsyncAction(
  'GET_STORE_KIT_LINKS_REQUEST',
  'GET_STORE_KIT_LINKS_REQUEST_SUCCESS',
  'GET_STORE_KIT_LINKS_REQUEST_FAILURE'
)<{ orgId: string }, LinkGeneratorType[], Error>()

export const createOrganisationLinks = createAsyncAction(
  'CREATE_STORE_KIT_LINKS_REQUEST',
  'CREATE_STORE_KIT_LINKS_REQUEST_SUCCESS',
  'CREATE_STORE_KIT_LINKS_REQUEST_FAILURE'
)<LinkGeneratorRequestType, LinkGeneratorType, Error>()

export const getAccessBookingsVenues = createAsyncAction(
  'GET_ACCESS_BOOKINGS_VENUES_REQUEST',
  'GET_ACCESS_BOOKINGS_VENUES_SUCCESS',
  'GET_ACCESS_BOOKINGS_VENUES_FAILURE'
)<{ orgId: string }, AccessVenueType[], Error>()

export const getRollerData = createAsyncAction(
  'GET_ROLLER_REQUEST',
  'GET_ROLLER_REQUEST_SUCCESS',
  'GET_ROLLER_REQUEST_FAILURE'
)<
  { orgId: string },
  {
    [key: string]: RollerVenuesType
  },
  Error
>()

export const postRollerData = createAsyncAction(
  'POST_ROLLER_REQUEST',
  'POST_ROLLER_REQUEST_SUCCESS',
  'POST_ROLLER_REQUEST_FAILURE'
)<
  {
    orgId: string
    venues: {
      [key: string]: Omit<RollerVenuesType, 'id' | 'webhook' | 'webhookId'>
    }
  },
  {
    [key: string]: RollerVenuesType
  },
  Error
>()

export const deleteRollerData = createAsyncAction(
  'DELETE_ROLLER_REQUEST',
  'DELETE_ROLLER_REQUEST_SUCCESS',
  'DELETE_ROLLER_REQUEST_FAILURE'
)<{ orgId: string; serial: string }, { serial: string }, Error>()

export const getOrganisationLabels = createAsyncAction(
  'GET_ORGANISATION_LABELS',
  'GET_ORGANISATION_LABELS_SUCCESS',
  'GET_ORGANISATION_LABELS_FAILURE'
)<
  {
    orgId: string
  },
  OrganisationLabelType[],
  Error
>()

export const addOrganisationLabelToImage = createAsyncAction(
  'ADD_ORGANISATION_LABELS_TO_IMAGE',
  'ADD_ORGANISATION_LABELS_TO_IMAGE_SUCCESS',
  'ADD_ORGANISATION_LABELS_TO_IMAGE_FAILURE'
)<
  {
    orgId: string
    imageId: string
    name: string
  },
  OrganisationLabelType,
  Error
>()

export const deleteOrganisationLabel = createAsyncAction(
  'DELETE_ORGANISATION_LABELS',
  'DELETE_ORGANISATION_LABELS_SUCCESS',
  'DELETE_ORGANISATION_LABELS_FAILURE'
)<
  {
    orgId: string
    label: OrganisationLabelType
  },
  OrganisationLabelType,
  Error
>()
