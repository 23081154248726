import { useEffect, useMemo } from 'react'
import { z } from 'zod'
import useWindowWidth from '@/hooks/useWindowWidth'
import morpheusApi from '../morpheus.slice'
import { useLocalStorage } from 'usehooks-ts'

export const userConfigDefault = z.object({
  dark_mode: z.boolean().default(false),
  use_system_colour_mode: z.boolean().default(true),
  action_bar: z
    .object({
      icon: z.boolean().default(true),
      label: z.boolean().default(false),
      draggable: z.boolean().default(true),
      position: z.enum(['top', 'bottom', 'left', 'right']).default('left'),
      vertical: z.boolean().default(false),
      has_updated: z.boolean().default(true),
      is_fixed: z.boolean().default(false),
    })
    .default({
      icon: true,
      label: false,
      draggable: false,
      position: 'left',
      has_updated: true,
      vertical: false,
      is_fixed: true,
    }),
})

function getDefaults<Schema extends typeof userConfigDefault>(schema: Schema) {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault) return [key, value._def.defaultValue()]
      return [key, undefined]
    })
  )
}

export type UserConfigType = z.infer<typeof userConfigDefault>

export const useUserConfig = () => {
  const {
    data: initData = getDefaults(userConfigDefault) as UserConfigType,
    isSuccess,
  } = morpheusApi.useGetUserConfigQuery(null, {
    refetchOnFocus: true,
  })

  const [data, setData] = useLocalStorage<UserConfigType>(
    'user-config',
    initData
  )

  useEffect(() => {
    if (!initData) return
    setData(initData)
  }, [initData])

  const { width } = useWindowWidth()

  const resData = useMemo(() => {
    if (width < 764) {
      return {
        ...data,
        isSuccess,
        action_bar: {
          ...data.action_bar,
          position: 'bottom',
          is_fixed: true,
          icon: true,
          label: true,
          vertical: false,
        },
      }
    }

    if (['left', 'right'].includes(data.action_bar.position)) {
      return {
        ...data,
        isSuccess,
        action_bar: {
          ...data.action_bar,
          vertical: true,

          position: data.action_bar.position,
        },
      }
    }
    return {
      ...data,
      isSuccess,
      action_bar: {
        ...data.action_bar,
        vertical: ['left', 'right'].includes(data.action_bar.position),

        position: data.action_bar.position,
      },
    }
  }, [data, isSuccess, width])

  return resData
}
