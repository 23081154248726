import { type FunctionComponent, type PropsWithChildren } from 'react'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import ItemsSkeleton, { NoItems } from './items.skeleton'
import OrganisationItem from './organisation.item'

interface PassedProps {
  search: string
}
interface QueryOptions {
  limit: number
  search?: string
}

const initOptions: QueryOptions = {
  limit: 20,
}

const OrganisationsList: FunctionComponent<PropsWithChildren<PassedProps>> = ({
  search,
}) => {
  const [cursor] = useQueryParam('cursor', withDefault(StringParam, ''))

  const { data: organisations = { data: [] }, isLoading } =
    morpheusApi.useGetOrganisationsSearchQuery({
      ...initOptions,
      //  cursor,
      search,
    })

  return (
    <ul className="space-y-1">
      {isLoading && organisations.data?.length === 0 ? (
        <ItemsSkeleton total={0} />
      ) : null}
      {!isLoading && organisations.data.length === 0 && <NoItems />}

      {organisations.data.map((item) => (
        <OrganisationItem key={item.id} {...item} />
      ))}
    </ul>
  )
}

export default OrganisationsList
