import QuillTextarea from '@/common/RichTextInput/quill'
import useGlobal from '@/hooks/useGlobal'
import { initThread } from '@/state/entities/inbox/inbox.types'
import inboxApi from '@/state/inbox/inbox.slice'
import { Link, useBoolean } from '@chakra-ui/react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid'
import { FormikProvider, useFormik } from 'formik'
import isEqual from 'lodash/isEqual'
import type { FunctionComponent } from 'react'
import { Fragment, useEffect } from 'react'
import {
  Icn,
  SButton,
  SIconButton,
  Textarea,
  buttonVariants,
  cn,
} from 'tailwind-ui'
import Label from 'tailwind-ui/src/forms/labels/label'
import EmailContacts from '../context/email/contacts'

interface ThreadMessageBoxProps {
  onChangeSendType: (type: 'messages' | 'notes') => void
  sendType: 'messages' | 'notes'
  onComplete: () => void
  threadId: string
  content?: string
}

function hasNotch() {
  if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
    let div = document.createElement('div')
    div.style.paddingBottom = 'env(safe-area-inset-bottom)'
    document.body.appendChild(div)
    let calculatedPadding = parseInt(
      window.getComputedStyle(div).paddingBottom,
      10
    )
    document.body.removeChild(div)
    if (calculatedPadding > 0) {
      return true
    }
  }
  return false
}

/**
 *
 * @todo - put these in another DIR
 */
const ThreadSendMessageBox: FunctionComponent<ThreadMessageBoxProps> = ({
  onChangeSendType,
  sendType,
  onComplete,
  threadId,
  content,
}) => {
  const { orgId } = useGlobal()

  const { data: thread = initThread, isLoading } =
    inboxApi.useGetInboxThreadQuery(
      {
        orgId,
        threadId,
      }
      // {
      //   skip: !threadId,
      // }
    )

  const [createContact] = inboxApi.useCreateEmailContactsOnThreadMutation()

  const [removeContact] = inboxApi.useRemoveEmailContactsOnThreadMutation()

  const [sendEvents] = inboxApi.useSendInboxThreadEventsMutation()

  const contactForm = useFormik({
    initialValues: {
      contacts: thread.contacts ?? [],
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {}
      if (!values.contacts) {
        errors.contacts = 'Contacts missing'
      }
      return errors
    },
    onSubmit: async ({ contacts }, { resetForm }) => {
      console.log({ contacts })

      resetForm()
    },
  })

  const [editEmail, setEditEmail] = useBoolean(
    contactForm.values.contacts.length === 0
  )
  /**
   *
   *
   * @todo - refactor this
   */
  useEffect(() => {
    if (contactForm.values.contacts.length === 0) {
      setEditEmail.on()
    } else {
      setEditEmail.off()
    }
  }, [contactForm.values.contacts.length])

  const formik = useFormik({
    initialValues: {
      description: '',
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.description) {
        errors.description = 'Content missing'
      }
      return errors
    },
    onSubmit: async ({ description }, { resetForm }) => {
      await sendEvents({
        type: sendType,
        description,
        orgId,
        threadId: thread.id,
        format: thread.method === 'email' ? 'html' : 'text',
      })
      onComplete()

      resetForm()
    },
  })

  useEffect(() => {
    if (
      isEqual(contactForm.initialValues.contacts, contactForm.values.contacts)
    )
      return
    console.log({ newContacts: 'hello' }, contactForm.values.contacts)
  }, [contactForm.values.contacts, contactForm.initialValues.contacts])

  useEffect(() => {
    // Update formik values when content changes
    formik.setValues({ ...formik.values, description: content ?? '' })
  }, [])

  {
    /**
    be careful of altering this as its used in a few parts of the product.
    if editing please remove chakra where used.
    */
  }
  return (
    <div
      className={cn('mb-2 ml-2 mr-1 p-2 rounded-lg space-y-1', {
        'bg-yellow-400/80 dark:bg-yellow-600/80 backdrop-blur-lg':
          sendType === 'notes',
        'bg-neutral-100/75 dark:bg-neutral-900/75 backdrop-blur-lg ':
          sendType !== 'notes',
        'max-lg:mb-[calc(0.5rem+env(safe-area-inset-bottom))]': hasNotch(),
        'max-lg:mb-6': !hasNotch(),
      })}
    >
      <div className="flex items-start gap-1">
        <Menu as="div" className="relative">
          <>
            <MenuButton
              className={cn(
                buttonVariants({ variant: 'ghost_default', size: 'sm' }),
                'flex items-center gap-2 capitalize'
              )}
            >
              <Icn
                icon={
                  sendType === 'notes' ? PencilSquareIcon : ArrowUturnLeftIcon
                }
                size="sm"
                className="size-3 fill-black/30 dark:fill-white/30"
              />
              {sendType === 'notes' ? 'note' : thread.method}
              <Icn
                icon={ChevronDownIcon}
                size="sm"
                className="size-3 fill-black/30 dark:fill-white/30"
              />
            </MenuButton>

            <MenuItems className="absolute z-50 flex flex-col space-y-0.5 mt-1 w-40 origin-top-right rounded-lg bg-white/80 dark:bg-black/80 p-1 shadow-lg ring-1 ring-black/5 dark:ring-white/5 backdrop-blur-xl focus:outline-none">
              <MenuItem as={Fragment}>
                <button
                  className={cn(
                    'group flex w-full items-center gap-2 rounded-lg px-3 py-1.5 text-sm ',
                    'data-[focus]:bg-black/10 dark:data-[focus]:bg-white/10',
                    { 'bg-black/10 dark:bg-white/10': sendType === 'notes' }
                  )}
                  onClick={() => {
                    onChangeSendType('notes')
                  }}
                >
                  Note
                </button>
              </MenuItem>
              <MenuItem as={Fragment}>
                <button
                  className={cn(
                    'group flex w-full items-center gap-2 rounded-lg px-3 py-1.5 text-sm capitalize',
                    'data-[focus]:bg-black/10 dark:data-[focus]:bg-white/10',
                    {
                      'bg-black/10 dark:bg-white/10': sendType === 'messages',
                    }
                  )}
                  onClick={() => {
                    onChangeSendType('messages')
                  }}
                >
                  {thread.method}
                </button>
              </MenuItem>
            </MenuItems>
          </>
        </Menu>

        {!editEmail &&
          sendType === 'messages' &&
          contactForm.values.contacts.length > 0 && (
            <Link fontSize="sm" onClick={setEditEmail.toggle}>
              {contactForm.values.contacts
                .map((contact) => contact.email)
                .join(', ')}
            </Link>
          )}
        {editEmail && sendType === 'messages' ? (
          <FormikProvider value={contactForm}>
            <form
              onSubmit={contactForm.handleSubmit as any}
              className="w-full flex gap-2 pt-0.5"
            >
              <SIconButton
                aria-label="collapse"
                icon={XMarkIcon}
                isRound
                onClick={setEditEmail.toggle}
                className="w-5 h-5"
                size="sm"
              />
              <Label>To:</Label>
              <EmailContacts
                contacts={contactForm.values.contacts}
                formName="contacts"
                onContactAdd={(cont) => {
                  if (!thread?.id) return
                  createContact({
                    orgId,
                    threadId: thread.id,
                    data: { ...cont, method: 'email' },
                  })
                }}
                onContactRemove={(cont) => {
                  if (!cont?.id || !thread?.id) return
                  removeContact({
                    orgId,
                    threadId: thread.id,
                    contactId: cont.id,
                  })
                }}
              />
            </form>
          </FormikProvider>
        ) : null}
      </div>

      <FormikProvider value={formik}>
        <form className="flex gap-1" onSubmit={formik.handleSubmit as any}>
          {thread.method === 'email' && (
            <QuillTextarea
              className="w-full bg-transparent px-2 border-0 h-auto min-h-16"
              disabled={!thread}
              onChange={(description) =>
                formik.setFieldValue('description', description)
              }
              placeholder={
                sendType === 'notes'
                  ? 'Write an internal note'
                  : 'Write a message'
              }
              value={formik.values.description}
            />
          )}
          {thread.method === 'sms' && (
            <Textarea
              className="bg-transparent border-0"
              disabled={!thread}
              onChange={({ target }) =>
                formik.setFieldValue('description', target.value)
              }
              placeholder={
                sendType === 'notes'
                  ? 'Write an internal note'
                  : 'Write a message'
              }
              value={formik.values.description}
              variant="unstyled"
            />
          )}
          <div className="self-end">
            <SButton
              variant={sendType === 'notes' ? 'default' : 'primary'}
              disabled={!thread || !formik.isValid}
              isLoading={formik.isSubmitting}
              type="submit"
              // className="border-0 rounded-full"
              brandKit={sendType !== 'notes'}
              className="rounded-full"
            >
              {sendType === 'notes' ? 'Save' : 'Send'}
            </SButton>
          </div>
        </form>
      </FormikProvider>
    </div>
  )
}

export default ThreadSendMessageBox
