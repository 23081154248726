import { usePathname } from '@/helpers/router'
import { useUserRole } from '@/hooks/useUser'
import useWindowWidth from '@/hooks/useWindowWidth'
import { useSelectedVenue } from '@/state/backend/venues/venues.hooks'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import inboxApi from '@/state/inbox/inbox.slice'
import { useUserConfig } from '@/state/morpheus/user-config/helpers'
import wifi from '@/state/wifi/wifi.slice'
import { getFirst } from '@/utils/common'
import {
  BookOpenIcon,
  ChartPieIcon,
  GiftIcon,
  HeartIcon,
  InboxIcon,
  MapPinIcon,
  PaperAirplaneIcon,
  StarIcon,
  SwatchIcon,
  WifiIcon,
} from '@heroicons/react/16/solid'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { ActionBarItemProps, MainMenuKeyType } from './types'

export const useMenu = ({
  toInclude = [
    'venues',
    'marketing',
    'inbox',
    'reviews',
    'loyalty',
    'gift-cards',
    'reports',
    'bookings',
    'wi-fi',
    'brand',
  ],
}: {
  toInclude?: Partial<MainMenuKeyType>[]
}) => {
  const { width } = useWindowWidth()

  // const size = useBreakpointValue({ base: 'md', lg: 'md' })
  const organisation = useSelectedOrganisation()
  const role = useUserRole()

  const { serial } = useParams<{ serial: string }>()

  const { data: selectedVenue } = useSelectedVenue(serial)
  const { data: unreadCount = { open: 0, closed: 0 } } =
    inboxApi.useGetUnreadCountQuery(
      {
        orgId: organisation?.id!,
        serial,
      },
      {
        skip: !organisation?.id,
      }
    )

  const toIncludeWithAuth = useMemo(() => {
    if (!role) return []
    switch (role?.key) {
      case 'marketeer':
        return toInclude.filter(
          (item) => !['gift-cards', 'venues', 'gift-cards'].includes(item)
        )
      case 'booking':
        return toInclude.filter(
          (item) =>
            !['gift-cards', 'loyalty', 'marketing', 'brand'].includes(item)
        )
      case 'reports':
        return toInclude.filter(
          (item) =>
            ![
              'marketing',
              'gift-cards',
              'inbox',
              'venues',
              'reviews',
              'loyalty',
              'brand',
            ].includes(item)
        )
      case 'moderator':
        return toInclude.filter(
          (item) =>
            !['marketing', 'gift-cards', 'reports', 'wi-fi'].includes(item)
        )
      case 'admin':
        return toInclude
      case 'super':
        return toInclude
      case 'reseller':
        return toInclude.filter(
          (item) =>
            ![
              'marketing',
              'gift-cards',
              'inbox',
              'reviews',
              'loyalty',
              'brand',
            ].includes(item)
        )
    }
  }, [toInclude, role])

  const [shadowVenue, setShadowVenue] = useLocalStorage(
    `active-menu-venue-${organisation?.id}`,
    selectedVenue
  )

  const isMobile = width < 768
  const MAX_MOBILE_ITEMS = shadowVenue ? 3 : 4

  useEffect(() => {
    if (!selectedVenue) return
    setShadowVenue(selectedVenue)
  }, [selectedVenue])

  const { data: wifiConfigs = [] } = wifi.useGetConfigsQuery({
    orgId: organisation?.id!,
  })

  const menuItems = useMemo((): {
    visible: ActionBarItemProps[]
    overflow: ActionBarItemProps[]
    venue: ActionBarItemProps[]
  } => {
    if (!toIncludeWithAuth) return { visible: [], overflow: [], venue: [] }
    const locations = organisation?.locations ?? []
    const firstLocation = getFirst(locations)
    const items = toIncludeWithAuth
      .map((item) => {
        switch (item) {
          case 'venues':
            return {
              name: 'Venues',
              icon: MapPinIcon,
              id: 'venues',
              to: `/${organisation?.id}/venues`,
            }

          case 'inbox':
            return locations.length === 1
              ? {
                  name: 'Inbox',
                  icon: InboxIcon,
                  id: 'inbox',
                  to: `/${organisation?.id}/venues/${firstLocation?.serial}/inbox`,
                  count: unreadCount.open,
                }
              : {
                  name: 'Inbox',
                  icon: InboxIcon,
                  id: 'inbox',
                  to: `/${organisation?.id}/inbox`,
                  count: unreadCount.open,
                }

          case 'marketing':
            return {
              name: 'Marketing',
              icon: PaperAirplaneIcon,
              id: 'marketing',
              to: `/${organisation?.id}/marketing`,
            }

          case 'reviews':
            return locations.length === 1
              ? {
                  name: 'Reviews',
                  icon: StarIcon,
                  id: 'reviews',
                  to: `/${organisation?.id}/venues/${firstLocation?.serial}/reviews`,
                }
              : {
                  name: 'Reviews',
                  icon: StarIcon,
                  id: 'reviews',
                  to: `/${organisation?.id}/reviews`,
                }

          case 'loyalty':
            return locations.length === 1
              ? {
                  name: 'Loyalty',
                  icon: HeartIcon,
                  id: 'loyalty',
                  to: `/${organisation?.id}/venues/${serial}/loyalty`,
                }
              : {
                  name: 'Loyalty',
                  icon: HeartIcon,
                  id: 'loyalty',
                  to: `/${organisation?.id}/loyalty`,
                }

          case 'gift-cards':
            return {
              name: 'Gifting',
              icon: GiftIcon,
              id: 'gift-cards',
              to: `/${organisation?.id}/gifting`,
            }
          case 'reports':
            return locations.length === 1 && firstLocation
              ? {
                  name: 'Reports',
                  icon: ChartPieIcon,
                  id: 'reports',
                  to: `/${organisation?.id}/venues/${firstLocation.serial}/reports`,
                }
              : {
                  name: 'Reports',
                  icon: ChartPieIcon,
                  id: 'reports',
                  to: `/${organisation?.id}/reports`,
                }
          case 'bookings':
            return locations.length === 1 && firstLocation
              ? {
                  name: 'Bookings',
                  icon: BookOpenIcon,
                  id: 'bookings',
                  to: `/${organisation?.id}/venues/${firstLocation.serial}/bookings`,
                }
              : {
                  name: 'Bookings',
                  icon: BookOpenIcon,
                  id: 'bookings',
                  to: `/${organisation?.id}/bookings`,
                }
          case 'wi-fi':
            return locations.length === 1 && firstLocation
              ? {
                  name: 'Wi-Fi',
                  icon: WifiIcon,
                  id: 'wi-fi',
                  to: `/${organisation?.id}/venues/${firstLocation.serial}/wi-fi`,
                }
              : {
                  name: 'Wi-Fi',
                  icon: WifiIcon,
                  id: 'wi-fi',
                  to: `/${organisation?.id}/wi-fi`,
                  count: wifiConfigs.filter((item) => !item.inform.status)
                    .length,
                }
          case 'brand':
            return locations.length === 1 && firstLocation
              ? {
                  name: 'Brand',
                  icon: SwatchIcon,
                  id: 'brand',
                  to: `/${organisation?.id}/venues/${firstLocation.serial}/brand`,
                }
              : {
                  name: 'Brand',
                  icon: SwatchIcon,
                  id: 'brand',
                  to: `/${organisation?.id}/organisation/brand`,
                }
        }
      })
      .filter(Boolean)

    const venueMenu = toIncludeWithAuth
      .map((item) => {
        switch (item) {
          case 'venues':
            return {
              name: shadowVenue?.alias ?? shadowVenue?.serial,
              icon: MapPinIcon,
              id: '',
              to: `/${organisation?.id}/venues/${shadowVenue?.serial}`,
            }
          case 'wi-fi':
            return {
              name: 'Wi-Fi',
              icon: WifiIcon,
              id: 'wi-fi',
              to: `/${organisation?.id}/venues/${shadowVenue?.serial}/wi-fi`,
            }
          case 'brand':
            return {
              name: 'Brand',
              icon: SwatchIcon,
              id: 'brand',
              to: `/${organisation?.id}/venues/${shadowVenue?.serial}/brand`,
            }
          case 'bookings':
            return {
              name: 'Bookings',
              icon: BookOpenIcon,
              id: 'bookings',
              to: `/${organisation?.id}/venues/${shadowVenue?.serial}/bookings`,
            }
          case 'inbox':
            return {
              name: 'Inbox',
              icon: InboxIcon,
              id: 'inbox',
              to: `/${organisation?.id}/venues/${shadowVenue?.serial}/inbox`,
              count: unreadCount.open,
            }
          case 'reviews':
            return {
              name: 'Reviews',
              icon: StarIcon,
              id: 'reviews',
              to: `/${organisation?.id}/venues/${shadowVenue?.serial}/reviews`,
            }
          case 'loyalty':
            return {
              name: 'Loyalty',
              icon: HeartIcon,
              id: 'loyalty',
              to: `/${organisation?.id}/venues/${shadowVenue?.serial}/loyalty`,
            }
          case 'reports':
            return {
              name: 'Monthly Snapshot',
              icon: ChartPieIcon,
              id: 'reports',
              to: `/${organisation?.id}/venues/${shadowVenue?.serial}/reports/monthly-snapshot`,
            }

          default:
            return null
        }
      })
      .filter(Boolean)

    if (isMobile) {
      return {
        visible: items.slice(0, MAX_MOBILE_ITEMS),
        overflow: items.slice(MAX_MOBILE_ITEMS),
        venue: selectedVenue ? venueMenu : [],
      }
    }

    return {
      visible: items,
      overflow: [],
      venue: shadowVenue ? venueMenu : [],
    }
  }, [
    toIncludeWithAuth,
    organisation?.locations,
    organisation?.id,
    isMobile,
    unreadCount.open,
    shadowVenue,
  ])

  return menuItems
}
