import { RootState } from '../reducers'
import { createAppSelector } from '@/state/helpers/createSelector'

export const getListStyle = (state: RootState) => {
  return state.global.listStyle
}

export const getQuerySelector = createAppSelector(
  ({ global }) => global.query,
  (query) => query
)
