import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import { isAxiosError } from 'axios'
import { useLocalStorage } from 'usehooks-ts'

const useSelectedOrganisation = () => {
  const { org_id } = useParams()
  const push = useNavigate()
  const { data, isError, error } = morpheusApi.useGetOrganisationQuery(
    `${org_id}`,
    {
      refetchOnFocus: false,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      skip: !org_id,
    }
  )

  const [cacheData, setCacheData] = useLocalStorage(`org_data_${org_id}`, data)

  useEffect(() => {
    if (!isError) return

    if (
      isAxiosError(error) &&
      error.response?.status &&
      error.response?.status >= 400
    ) {
      push('/')
    }
  }, [isError, error])

  useEffect(() => {
    if (data) {
      setCacheData(data)
    }
  }, [data])

  return cacheData
}

export const useSelectedOrganisationWithChildren = () => {
  const { org_id } = useParams()
  const { data = [] } = morpheusApi.useGetOrganisationChildrenQuery(org_id!, {
    skip: !org_id,
  })
  const org = useSelectedOrganisation()

  return useMemo(() => {
    if (!org) {
      return null
    }
    return { ...org, children: data }
  }, [org, data])
}

export default useSelectedOrganisation
