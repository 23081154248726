import { createApi, retry } from '@reduxjs/toolkit/query/react'
import config from '@/config'
import { buildUrl } from '@/utils/common'
import { axiosBaseQuery } from '../helpers'
import type {
  AnomalyType,
  ReportCustomerChartType,
  ReportCustomerTotalType,
  TransactionReport,
} from './reports.type'

const service = 'reports'

const reportsApi = createApi({
  reducerPath: service,

  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: config.url.reports,
      service,
    }),
    {
      maxRetries: 5,
    }
  ),
  endpoints: (build) => ({
    getBenchmarkAverages: build.query<
      {
        _sum: {
          in_venue_visits_count: number
          total_customers_count: number
          new_customer_count: number
          interaction_count: number
          marketing_email_sent_count: number
          marketing_email_delivered_count: number
          marketing_email_opt_in_count: number
          marketing_sms_opt_in_count: number
          marketing_data_opt_in_count: number
          inbox_threads_created_count: number
          return_visits_from_marketing_count: number
        }
        _count: number
        _max: {
          in_venue_visits_count: number
          total_customers_count: number
          new_customer_count: number
          interaction_count: number
          marketing_email_sent_count: number
          marketing_email_delivered_count: number
          marketing_email_opt_in_count: number
          marketing_sms_opt_in_count: number
          marketing_data_opt_in_count: number
          inbox_threads_created_count: number
          return_visits_from_marketing_count: number
          organisation_id: string
        }
        _min: {
          in_venue_visits_count: number
          total_customers_count: number
          new_customer_count: number
          interaction_count: number
          marketing_email_sent_count: number
          marketing_email_delivered_count: number
          marketing_email_opt_in_count: number
          marketing_sms_opt_in_count: number
          marketing_data_opt_in_count: number
          inbox_threads_created_count: number
          return_visits_from_marketing_count: number
          organisation_id: string
        }
        _avg: {
          in_venue_visits_count: number
          total_customers_count: number
          new_customer_count: number
          interaction_count: number
          marketing_email_sent_count: number
          marketing_email_delivered_count: number
          marketing_email_opt_in_count: number
          marketing_sms_opt_in_count: number
          marketing_data_opt_in_count: number
          inbox_threads_created_count: number
          return_visits_from_marketing_count: number
        }
      },
      void
    >({
      query: () => ({
        method: 'get',
        url: '/benchmark/averages',
      }),
    }),

    getAdminTotals: build.query<
      {
        total_customers_count: number
        new_customer_count: number
        interaction_count: number
        marketing_email_sent_count: number
        marketing_email_delivered_count: number
        marketing_email_opt_in_count: number
        marketing_sms_opt_in_count: number
        marketing_data_opt_in_count: number
        inbox_threads_created_count: number
        return_visits_from_marketing_count: number
      },
      void
    >({
      query: () => ({
        method: 'get',
        url: '/totals',
      }),
    }),
    getAnomalies: build.query<AnomalyType[], void>({
      query: () => ({
        url: `/anomaly/anomalies`,
        method: 'GET',
      }),
    }),
    getTransaction: build.query<
      TransactionReport,
      { org_id: string; serial?: string }
    >({
      query: ({ org_id, serial }) => ({
        url: buildUrl(`/${org_id}/transactions`, { serial }),
        method: 'GET',
      }),
    }),
    getTransactionBySource: build.query<
      {
        totals: ReportCustomerTotalType
        chart: ReportCustomerChartType[]
      },
      { org_id: string; serial?: string }
    >({
      query: ({ org_id, serial }) => ({
        url: buildUrl(`/${org_id}/transactions/source`, { serial }),
        method: 'GET',
      }),
    }),
    getTransactionBySourceAndTime: build.query<
      TransactionReport,
      { org_id: string; start: Date; end: Date; serial?: string }
    >({
      query: ({ org_id, start, end, serial }) => ({
        url: buildUrl(`/${org_id}/transactions/time/source`, {
          start,
          end,
          serial,
        }),
        method: 'GET',
      }),
    }),
    getTransactionByTime: build.query<
      TransactionReport,
      { org_id: string; start: Date; end: Date; serial?: string }
    >({
      query: ({ org_id, start, end, serial }) => ({
        url: buildUrl(`/${org_id}/transactions/time`, { start, end, serial }),
        method: 'GET',
      }),
    }),
    getCustomers: build.query<
      {
        totals: ReportCustomerTotalType
      },
      { org_id: string }
    >({
      query: ({ org_id }) => ({
        url: `/${org_id}/customers`,
        method: 'GET',
      }),
    }),
    getCustomersByTime: build.query<
      {
        totals: ReportCustomerTotalType
        chart: ReportCustomerChartType[]
      },
      { org_id: string; start: Date; end: Date; serial?: string }
    >({
      query: ({ org_id, start, end, serial }) => ({
        url: buildUrl(`/${org_id}/customers/time`, { start, end, serial }),
        method: 'GET',
      }),
    }),
    getCustomersBySource: build.query<
      {
        totals: ReportCustomerTotalType
        chart: ReportCustomerChartType[]
      },
      { org_id: string; serial?: string }
    >({
      query: ({ org_id, serial }) => ({
        url: buildUrl(`/${org_id}/customers/source`, { serial }),
        method: 'GET',
      }),
      transformResponse: (response: {
        totals: ReportCustomerTotalType
        chart: ReportCustomerChartType[]
      }) => {
        return {
          ...response,
          chart: [...response.chart].sort(
            (a, b) => b.interaction_count - a.interaction_count
          ),
        }
      },
    }),
    getBenchmarkThirtyDayRolling: build.query<
      {
        _sum: {
          in_venue_visits_count: number
          total_customers_count: number
          new_customer_count: number
          interaction_count: number
          marketing_email_sent_count: number
          marketing_email_delivered_count: number
          marketing_email_opt_in_count: number
          marketing_sms_opt_in_count: number
          marketing_data_opt_in_count: number
          inbox_threads_created_count: number
          return_visits_from_marketing_count: number
        }
        _count: number
        _max: {
          in_venue_visits_count: number
          total_customers_count: number
          new_customer_count: number
          interaction_count: number
          marketing_email_sent_count: number
          marketing_email_delivered_count: number
          marketing_email_opt_in_count: number
          marketing_sms_opt_in_count: number
          marketing_data_opt_in_count: number
          inbox_threads_created_count: number
          return_visits_from_marketing_count: number
          serial: string
        }
        _min: {
          in_venue_visits_count: number
          total_customers_count: number
          new_customer_count: number
          interaction_count: number
          marketing_email_sent_count: number
          marketing_email_delivered_count: number
          marketing_email_opt_in_count: number
          marketing_sms_opt_in_count: number
          marketing_data_opt_in_count: number
          inbox_threads_created_count: number
          return_visits_from_marketing_count: number
          serial: string
        }
        _avg: {
          in_venue_visits_count: number
          total_customers_count: number
          new_customer_count: number
          interaction_count: number
          marketing_email_sent_count: number
          marketing_email_delivered_count: number
          marketing_email_opt_in_count: number
          marketing_sms_opt_in_count: number
          marketing_data_opt_in_count: number
          inbox_threads_created_count: number
          return_visits_from_marketing_count: number
        }
      },
      void
    >({
      query: () => ({
        method: 'get',
        url: '/benchmark/thirty-day-rolling',
      }),
    }),
  }),
})

export default reportsApi
