import { type FunctionComponent, type PropsWithChildren } from 'react'
import { Skeleton } from 'tailwind-ui'

interface PassedProps {
  total?: number
}

const ItemsSkeleton: FunctionComponent<PropsWithChildren<PassedProps>> = ({
  total,
}) => {
  return (
    <div className="p-2 space-y-1 rounded-1.5-consentric">
      {Array(total ?? 8)
        .fill(0)
        .map((_, k) => (
          <Skeleton key={k} className="rounded-lg h-16" />
        ))}
    </div>
  )
}

export function NoItems() {
  return (
    <li className="rounded-lg block mx-3 py-3">
      <span className="inline-block font-semibold">No results found</span>
    </li>
  )
}

export default ItemsSkeleton
