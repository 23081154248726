import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useAppDispatch,
  useAppSelector as useSelector,
} from '@/state/helpers/useApp'

import { setLoadedSerial } from '@/state/entities/loaded/loaded.actions'
import * as uiActions from '@/state/entities/ui/ui.actions'
import * as actions from '@/state/global/global.actions'
import { useAppSelector } from '@/state/helpers/useApp'
import { RootState } from '@/state/reducers'
import { OrganisationLocationType } from '@/connect-types/organisations/organisations.type'
import { useParams } from 'react-router-dom'

const useGlobal = () => {
  const dispatch = useAppDispatch()

  const toggleFilter = useCallback(
    () => dispatch(actions.toggleFilter()),
    [dispatch]
  )
  const globalOrgId = useAppSelector(({ global }) => global.orgId)

  const isAddBookingOpen = useAppSelector(
    ({ entities }) => entities.ui.bookingAddModalOpen
  )
  const setIsAddBookingOpen = useCallback(
    (open: boolean) => dispatch(uiActions.setAddBookingModalVisible(open)),
    [dispatch]
  )
  const openMenu = useCallback(() => dispatch(actions.openMenu()), [dispatch])

  const organisationModal = useCallback(
    (open: boolean) => dispatch(actions.organisationModal(open)),
    [dispatch]
  )
  const addLocationModal = useCallback(
    (open: boolean) => dispatch(actions.addLocationModalSwitch(open)),
    [dispatch]
  )
  const closeMenu = useCallback(() => dispatch(actions.closeMenu()), [dispatch])
  const venuesModalVisible = useAppSelector(
    ({ entities }) => entities.ui.venuesModalVisible
  )
  const addLocationModalVisible = useSelector(
    ({ global }: RootState) => global.addLocationModalVisible
  )
  const mimicBanner = useSelector(({ global }: RootState) => global.mimicBanner)
  const selectedReviewPageId = useSelector(
    ({ global }: RootState) => global.selectedReviewPageId
  )
  const setSelectedReviewPageId = useCallback(
    (id: string) => {
      dispatch(actions.setSelectedReviewPageId(id))
    },
    [dispatch]
  )
  const setVenuesModalVisible = useCallback(
    (visible: boolean) => dispatch(uiActions.setVenuesModalVisible(visible)),
    [dispatch]
  )

  const { org_id: orgId } = useParams<{ org_id: string }>()
  const menu = useSelector(({ global }: RootState) => global.menu)
  const organisationModalVisible = useSelector(
    ({ global }: RootState) => global.organisationModalVisible
  )
  const setOrgId = useCallback(
    (orgId: string) => dispatch(actions.setOrgId(orgId)),
    [dispatch]
  )
  const setSelectedLocation = useCallback(
    (location: OrganisationLocationType) => {
      dispatch(actions.setSelectedLocation(location))
      if (!location) return
      dispatch(setLoadedSerial(location.serial))
    },
    [dispatch]
  )

  const clearSelectedLocation = useCallback(() => {
    dispatch(actions.clearSelectedLocation())
  }, [dispatch])

  const clearGlobal = useCallback(() => {
    dispatch(actions.clearAll())
  }, [dispatch])

  const setMimicBanner = useCallback(
    (visible: boolean) => {
      dispatch(actions.setMimicBanner(visible))
    },
    [dispatch]
  )

  interface UseLongPressOptions {
    onLongPress: () => void
    onClick?: () => void
    delay?: number
  }

  const useLongPress = ({
    onLongPress,
    onClick,
    delay = 500, // Default delay of 500ms
  }: UseLongPressOptions) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false)
    const timerRef = useRef<NodeJS.Timeout | null>(null)

    const start = useCallback(
      (event: React.MouseEvent | React.TouchEvent | React.KeyboardEvent) => {
        event.preventDefault() // Prevent default behavior (e.g., context menu)
        setLongPressTriggered(false)
        timerRef.current = setTimeout(() => {
          onLongPress()
          setLongPressTriggered(true)
        }, delay)
      },
      [onLongPress, delay]
    )

    const clear = useCallback(
      (event: React.MouseEvent | React.TouchEvent | React.KeyboardEvent) => {
        if (timerRef.current) {
          clearTimeout(timerRef.current)
        }
        if (!longPressTriggered && onClick) {
          onClick()
        }
      },
      [onClick, longPressTriggered]
    )

    // Cleanup the timer on unmount
    useEffect(() => {
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current)
        }
      }
    }, [])

    // Keyboard event handlers
    const onKeyDown = useCallback(
      (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
          start(event)
        }
      },
      [start]
    )

    const onKeyUp = useCallback(
      (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
          clear(event)
        }
      },
      [clear]
    )

    // Prevent context menu on long press
    const onContextMenu = useCallback((event: React.MouseEvent) => {
      event.preventDefault()
    }, [])

    return {
      onMouseDown: start,
      onTouchStart: start,
      onMouseUp: clear,
      onMouseLeave: clear,
      onTouchEnd: clear,
      onTouchMove: clear, // Cancel if touch moves
      onKeyDown, // For keyboard support
      onKeyUp, // For keyboard support
      onContextMenu, // Prevent context menu
      tabIndex: 0, // Make the element focusable
      role: 'button', // Accessibility role
    }
  }

  return {
    toggleFilter,
    clearGlobal,
    setOrgId,
    orgId: orgId ? String(orgId) : globalOrgId,
    openMenu,
    closeMenu,
    menu,
    setVenuesModalVisible,
    venuesModalVisible,
    addLocationModal,
    setSelectedLocation,
    clearSelectedLocation,
    organisationModal,
    organisationModalVisible,
    addLocationModalVisible,
    setMimicBanner,
    mimicBanner,
    selectedReviewPageId,
    setSelectedReviewPageId,
    isAddBookingOpen,
    setIsAddBookingOpen,
    useLongPress,
  }
}

export default useGlobal
