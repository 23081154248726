import { ArrowRightIcon } from '@heroicons/react/16/solid'
import { type FunctionComponent, type PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import { type SimpleOrgType } from '@/state/global/global.types'

const OrganisationItem: FunctionComponent<PropsWithChildren<SimpleOrgType>> = ({
  id,
  name,
}) => {
  return (
    <li key={id}>
      <NavLink
        to={id}
        className={({ isActive }) =>
          `w-full items-center rounded-xl rounded-1.5-consentric p-2 flex justify-between group ${isActive ? 'bg-gray-100 dark:bg-gray-800' : ''
          } hover:bg-black/10  dark:hover:bg-white/10`
        }
      >
        <span className="w-full">{name}</span>
        <ArrowRightIcon className="group-hover:text-stampede-500 relative group-hover:translate-x-1 transition-transform ease-in-out duration-300 text-gray-600 dark:text-gray-500 h-5 w-5" />
      </NavLink>
    </li>
  )
}

export default OrganisationItem
