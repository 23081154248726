import useUser from '@/hooks/useUser'
import { gravatarImage } from '@/utils/common'
import {
  Box,
  HStack,
  Stack,
  type StackProps,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { BuildingLibraryIcon } from '@heroicons/react/16/solid'
import { type ReactElement, type ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { Avatar } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'

interface TextWithIconProps extends StackProps {
  icon: ReactElement
  children: ReactNode
}
function TextWithIcon(props: TextWithIconProps) {
  const { icon, children, ...rest } = props
  return (
    <HStack {...rest}>
      <Box color={mode('gray.400', 'gray.600')} aria-hidden>
        {icon}
      </Box>
      <Text
        fontWeight="medium"
        fontSize={{ base: 'xs', md: 'sm' }}
        lineHeight="1"
      >
        {children}
      </Text>
    </HStack>
  )
}

function Profile() {
  const user = useUser()
  if (!user) return <></>
  return (
    <Stack
      spacing={{ base: '8', md: '4' }}
      direction={{ base: 'column', lg: 'row' }}
      justify="space-between"
      align="flex-start"
    >
      <div className="bg-white/20 dark:bg-black/20  rounded-full flex gap-2 p-1 pl-4 items-center">
        <div>
          <Heading className="text-sm mt-0">
            {user.first} {user.last}
          </Heading>
          {user.role === 0 ? (
            <NavLink to="admin" className="group ">
              <Description className="flex gap-1 text-xs items-center group-hover:text-stampede-500">
                <BuildingLibraryIcon className="size-4" />
                <span>{user.company}</span>
              </Description>
            </NavLink>
          ) : (
            <Description>{user.company}</Description>
          )}
        </div>

        <Avatar
          name={`${user.first} ${user.last}`}
          src={gravatarImage(user.email, 'gallery')}
        />
      </div>
    </Stack>
  )
}

export default Profile
