import { HStack, Text } from '@chakra-ui/react'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { format } from 'date-fns'
import { FunctionComponent } from 'react'
import { InboxThreadEventEventableSystemNoteType } from '@/state/entities/inbox/inbox.types'

const ThreadSystemNoteBox: FunctionComponent<{
  event: InboxThreadEventEventableSystemNoteType
}> = ({ event }) => {
  return (
    <div className="flex items-center justify-center py-2" role="group">
      <div className="text-sm text-neutral-600 text-center">
        {format(event.created_at, TimeFormats.FourHourApple)} -{' '}
        {event.description}
      </div>
    </div>
  )
}

export default ThreadSystemNoteBox
