'use client'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cn } from 'tailwind-ui'

export interface TooltipProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof tooltipVariants> {
  information?: string | React.ReactNode
  side?: 'top' | 'bottom' | 'left' | 'right'
  open?: boolean
}

export const tooltipVariants = cva([
  'data-[state=delayed-open]:animate-slide-up-fade',
  'data-[state=closed]:animate-slide-down-fade',
  'data-[side=bottom]:origin-top',
  'data-[side=left]:origin-right',
  'data-[side=right]:origin-left',
  'data-[side=top]:origin-bottom',
])

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'rounded-2xl dark:text-black/90 text-white/90 dark:bg-white/80 bg-black/80 backdrop-blur-xl border border-white/5',
      'z-50 overflow-hidden px-3 py-1.5 text-sm  shadow-md',
      'transform-origin-[var(--radix-tooltip-content-transform-origin)]',
      tooltipVariants(),
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export function Tooltip({
  information,
  children,
  className,
  side = 'bottom',
  open,
}: TooltipProps) {
  const isTouchDevice = React.useMemo(() => {
    if (typeof window === 'undefined') return false
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0
  }, [])

  // Check if first child is a button
  const isButtonChild =
    React.isValidElement(children) &&
    (children.type === 'button' || children.type?.displayName === 'Button')

  // Return children without tooltip if touch device and button child
  if (isTouchDevice && isButtonChild) {
    return children
  }

  if (!information) return children
  return (
    <TooltipPrimitive.Provider delayDuration={0} skipDelayDuration={500}>
      <TooltipPrimitive.Root open={open}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipContent
            sideOffset={4}
            side={side}
            className={cn(tooltipVariants({ className }))}
          >
            {information}
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
