export const isInStandaloneMode = (): boolean => {
  return (
    window.matchMedia('(display-mode: minimal-ui)').matches ||
    // Check for display-mode: standalone (most modern browsers)
    window.matchMedia('(display-mode: standalone)').matches ||
    // Check for iOS standalone mode
    (window.navigator as any).standalone === true ||
    // Check for Android TWA (Trusted Web Activity)
    document.referrer.includes('android-app://') ||
    // Additional check for Safari on iOS
    ('standalone' in window.navigator && (window.navigator as any).standalone)
  )
}
