import { throttle } from 'lodash-es'
import { useCallback, useLayoutEffect, useState } from 'react'

const getPxNumber = (value: string) => {
  if (!value) return 0

  if (typeof value === 'string') {
    if (!value.toString().includes('px')) {
      return 0
    }
    return Number(value.toString().replace('px', ''))
  }
  return 0
}

const useWindowWidth = () => {
  const heightOffset = getPxNumber(
    getComputedStyle(document.documentElement).getPropertyValue('--sat')
  )

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight - heightOffset,
    width: window.innerWidth,
  })

  const handleResize = useCallback(
    throttle(() => {
      setDimensions({
        height: window.innerHeight - heightOffset,
        width: window.innerWidth,
      })
    }, 1000),
    [heightOffset]
  )

  useLayoutEffect(() => {
    setTimeout(() => {
      handleResize()
    })

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return dimensions
}

export default useWindowWidth
