import { createReducer } from '@reduxjs/toolkit'
import { subDays, startOfDay } from 'date-fns'
import { OrganisationRegistrationReportType } from '../insight/insight.type'
import { setQuery } from './reports.actions'
import { ReportQueryType } from './reports.type'

export interface StateType {
  customers: {
    isLoading: boolean
    totals: OrganisationRegistrationReportType | null
  }
  query: ReportQueryType
}

const initialState: StateType = {
  customers: {
    isLoading: false,
    totals: null,
  },
  query: {
    start: subDays(startOfDay(new Date()), 30).getTime(),
    end: new Date().getTime(),
  },
}

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setQuery, (state, action) => {
    state.query = {
      ...state.query,
      ...(action.payload as Partial<ReportQueryType>),
    }
  })
)

export default reducer
