import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export const usePathname = () => {
  const { org_id } = useParams()
  const { pathname } = useLocation()
  return `${pathname.replace(`/${org_id}`, '')}`
}

function useSaveLastRoute() {
  const location = useLocation()

  useEffect(() => {
    const excludedPaths = ['/', 'oauth', 'code']
    const shouldSave = !excludedPaths.some((path) =>
      location.pathname.includes(path)
    )

    if (shouldSave) {
      localStorage.setItem('last-visited-pathname', location.pathname)
    } else {
      localStorage.removeItem('last-visited-pathname')
    }
  }, [location])
}

export default useSaveLastRoute
