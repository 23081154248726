import type { StackProps } from '@chakra-ui/react'
import {
  Icon,
  Badge,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Link as ChakLink,
  Progress,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/16/solid'
import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { usePageTitle } from '@/hooks/usePageTitle'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'

interface PassedProps {
  title: string | ReactElement
  subTitle?: string
  onBack?: string | boolean
  center?: ReactElement
  isLoading?: boolean
  isCollapsed?: boolean
  leftElement?: ReactElement
  isMain?: boolean
}

type StackPropsWithoutOverlap = Omit<StackProps, 'title'>

function PageHeader({
  title,
  children,
  onBack,
  center,
  isLoading,
  isCollapsed = false,
  subTitle,
  leftElement,
  isMain = false,
  ...stackProps
}: PropsWithChildren<PassedProps & StackPropsWithoutOverlap>) {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const organisation = useSelectedOrganisation()

  usePageTitle(typeof title === 'string' ? title : '')

  return (
    <Stack
      bg="bg-default"
      display={isCollapsed ? 'none' : 'block'}
      spacing={2}
      zIndex={10}
      {...stackProps}
    >
      <Box h="2px" />
      <Grid
        alignContent="stretch"
        gap={1}
        gridTemplateRows="min-content"
        pb={2}
        pt={{ base: 2, lg: 2 }}
        templateColumns={{
          base: `repeat(${center ? '2' : '3'}, 1fr)`,
          md: 'repeat(3, 1fr)',
        }}
        {...(isMain && {
          px: { base: 4, lg: 8 },
        })}
      >
        <GridItem area="header title" order={1}>
          <HStack h="full" spacing={0}>
            {onBack ? (
              <IconButton
                _active={{}}
                _focus={{}}
                _hover={{ color: 'brand.500' }}
                aria-label="go-back"
                as={Link}
                icon={<Icon as={ChevronLeftIcon} />}
                minW="auto"
                pl={0}
                pr={4}
                size="md"
                className="back-item"
                to={typeof onBack === 'string' ? onBack : '..'}
                variant="ghost"
              />
            ) : null}
            {leftElement ? leftElement : null}

            <HStack w="full">
              <HStack spacing={0} w="full">
                {typeof title === 'string' ? (
                  <Heading
                    fontSize={{
                      base: 'lg',
                      md: 'revert',
                    }}
                    isTruncated
                    maxW="300px"
                    size="sm"
                  >
                    {title}
                  </Heading>
                ) : (
                  title
                )}
                {subTitle ? (
                  <Badge
                    borderBottomRadius="md"
                    borderTopRadius="none"
                    color="muted"
                    position="absolute"
                    px={2}
                    size="xs"
                    top={0}
                  >
                    {subTitle}
                  </Badge>
                ) : null}
              </HStack>
              {!center && organisation && !isMobile ? (
                <Tag borderRadius="full" display="none" variant="subtle">
                  <TagLabel>{organisation.name}</TagLabel>
                  <TagCloseButton>
                    <ChakLink as={Link} to="/">
                      <Icon as={XMarkIcon} boxSize="16px" />
                    </ChakLink>
                  </TagCloseButton>
                </Tag>
              ) : null}
            </HStack>
          </HStack>
        </GridItem>
        {center ? (
          <GridItem
            area="header center"
            colSpan={{
              base: 3,
              md: 1,
            }}
            order={{
              base: 3,
              md: 2,
            }}
          >
            <Flex align="center" h="full" justify="center" w="full">
              {center}
            </Flex>
          </GridItem>
        ) : null}
        <GridItem
          area="header end"
          colSpan={{
            base: !center ? 2 : 1,
          }}
          justifyContent="end"
          order={2}
        >
          <HStack h="full" justifyContent="end" spacing={{ base: 1, lg: 2 }}>
            {children}
          </HStack>
        </GridItem>
      </Grid>

      <Progress
        bg="transparent"
        borderRadius="none"
        h="2px"
        isIndeterminate={isLoading}
        size="xs"
      />
    </Stack>
  )
}

export default PageHeader
