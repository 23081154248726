import { configureStore } from '@reduxjs/toolkit'

import createSagaMiddleware from 'redux-saga'
import { setupListeners } from '@reduxjs/toolkit/query'
import config from '@/config'
import agentSmithApi from './agent-smith/agent-smith.slice'
import backendApi from './backend/backend.slice'
import billingApi from './billing/billing.slice'
import bookingApi from './booking/booking.slice'
import inboxApi from './inbox/inbox.slice'
import integrationsApi from './integrations/integrations.slice'
import loyaltyApi from './loyalty/loyalty.slice'
import morpheusApi from './morpheus/morpheus.slice'
import tankApi from './tank/tank.slice'
import { notificationApi } from './notification/notification.slice'
import { rootReducer } from './reducers'
import reportsApi from './reports/reports.slice'
import rootSaga from './sagas'
import serviceApi from './service/service.slice'
import wifiApi from './wifi/wifi.slice'
import zionApi from './zion/zion.slice'
import sitesApi from './sites/sites.slice'
import cypherApi from './cypher/cypher.slice'

const configureAppStore = (preloadedState: any = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const theStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        ignoredActions: [],
      })
        .concat(sagaMiddleware)
        .concat(backendApi.middleware)
        .concat(serviceApi.middleware)
        .concat(bookingApi.middleware)
        .concat(morpheusApi.middleware)
        .concat(tankApi.middleware)
        .concat(wifiApi.middleware)
        .concat(zionApi.middleware)
        .concat(integrationsApi.middleware)
        .concat(reportsApi.middleware)
        .concat(inboxApi.middleware)
        .concat(billingApi.middleware)
        .concat(loyaltyApi.middleware)
        .concat(agentSmithApi.middleware)
        .concat(notificationApi.middleware)
        .concat(sitesApi.middleware)
        .concat(cypherApi.middleware),
    preloadedState,
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({
        autoBatch: { type: 'raf' },
      }),

    // enhancers: (defaultEnhancers) => [enhancers, ...defaultEnhancers],
    devTools: !config.prod,
  })

  setupListeners(theStore.dispatch)

  sagaMiddleware.run(rootSaga)
  /*
  if (import.meta.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => theStore.replaceReducer(rootReducer))
    module.hot.accept('./sagas', () => {
      const getNewSagas = require('./sagas')
      sagaTask.cancel()
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(getNewSagas.default)
      })
    })
  }
  */

  return theStore
}

export default () => {
  const store = configureAppStore()
  //const persistor = persistStore(store)

  return { store }
}
