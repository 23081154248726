import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react'
import { SIconButton, cn, cx } from 'tailwind-ui'
import { XMarkIcon } from '@heroicons/react/16/solid'
import { useInView } from 'react-intersection-observer'
import { useHideZendesk } from '@/helpers/zendesk'

function Drawer({
  open = true,
  setOpen,
  children,
  title = '',
  size = 'lg',
}: {
  open: boolean
  setOpen: (isOpen: boolean) => void
  children: React.ReactNode

  title: string
  size?: 'sm' | 'md' | 'lg'
}) {
  const sizeClass =
    size === 'sm'
      ? 'lg:w-1/4 lg:max-w-1/4'
      : size === 'md'
        ? 'lg:w-2/4 lg:max-w-2/4'
        : 'lg:w-3/4 lg:max-w-3/4'
  const handleDrawerClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  const [inViewRef, isInView] = useInView()
  useHideZendesk(isInView)

  return (
    <Dialog open={open} onClose={setOpen} transition className="relative z-50">
      <DialogBackdrop
        className="fixed inset-0 duration-300 ease-out data-[closed]:opacity-0 bg-white/50 dark:bg-black/50 backdrop-blur-sm"
        transition
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div
            className={cx(
              'pointer-events-none lg:py-2 pl-2 fixed inset-y-0 right-0 flex w-full max-w-full',
              sizeClass
            )}
          >
            <DialogPanel
              transition
              className={cn(
                'w-full overflow-hidden',
                'lg:border-l lg:border-t lg:border-b border-black/5 dark:border-white/5',
                'bg-neutral-100/90 dark:bg-neutral-900/90 backdrop-blur-xl ',
                `flex flex-col pointer-events-auto shadow-md  rounded-l-xl  duration-300 ease-out data-[closed]:translate-x-full `
              )}
              ref={inViewRef}
            >
              <div className=" flex pr-4 pl-2 py-2 space-x-2 justify-start items-center ">
                <SIconButton
                  icon={XMarkIcon}
                  variant="ghost_default"
                  type="button"
                  size="sm"
                  onClick={() => {
                    setOpen(false)
                  }}
                />
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold capitalize leading-6 text-gray-900 dark:text-gray-50 pr-2"
                >
                  {title}
                </DialogTitle>
              </div>
              <div className="flex-1 overflow-x-scroll overflow-y-scroll">
                {children}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
export default Drawer
