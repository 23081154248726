import { useEffect, useMemo, useState } from 'react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/16/solid'
import { ButtonGroup, SButton, SIconButton, Select, cx } from 'tailwind-ui'
import type { PagingLinksType } from '@/connect-types/backend/service'

interface PassedProps {
  pageSize: number
  padding?: number
  pageIndex: number
  total?: number
  showSizeChanger?: boolean
  className?: string
  onChange?: ({
    current,
    pageSize,
    nextLink,
  }: {
    current: number
    pageSize: number
    nextLink: string
  }) => void
  isLoading?: boolean
  cursor?: PagingLinksType
  onPageIndexChange?: (pageIndex: number) => void
}
const rowsPerPageOptions = [5, 10, 25, 50]

const range = (from: number, to: number, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

function Pagination({
  onChange,
  showSizeChanger,
  pageSize,
  pageIndex: initPageIndex,
  total,
  cursor,
  className,
  onPageIndexChange,
}: PassedProps) {
  const [pageIndex, setPageIndex] = useState(initPageIndex)
  const nextPage = () => {
    setPageIndex((i) => {
      const newPageIndex = i + 1
      onPageIndexChange?.(newPageIndex)
      return newPageIndex
    })
  }
  const prevPage = () => {
    setPageIndex((i) => {
      const newPageIndex = i - 1
      onPageIndexChange?.(newPageIndex)
      return newPageIndex
    })
  }

  const setPageSize = (size: number) => {
    onChange?.({ pageSize: size, current: pageIndex, nextLink: '' })
  }

  useEffect(() => {
    setPageIndex(initPageIndex)
  }, [initPageIndex])

  const totalPages = useMemo(
    () => Math.ceil(total / pageSize),
    [total, pageSize]
  )

  const canPreviousPage = useMemo(() => {
    return pageIndex > 0
  }, [pageIndex])

  const canNextPage = useMemo(() => {
    return pageIndex + 1 < totalPages
  }, [pageIndex, totalPages])

  const getNumbers = useMemo(() => {
    const pageNeighbours = 1
    const currentPage = pageIndex

    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2
    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
      let pages: any = range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, totalPages]
    }

    return range(1, totalPages)
  }, [pageIndex, totalPages])

  return (
    <div
      className={cx(
        'inline-flex w-fit p-1 items-center justify-start  rounded-full gap-1 sticky bottom-2 left-2',
        {
          'bg-black/10 dark:bg-white/10 backdrop-blur-lg border border-black/5 dark:border-white/5 justify-start':
            !cursor,
        },
        className
      )}
    >
      {Boolean(cursor) && (
        <ButtonGroup className="dark:bg-transparent bg-transparent rounded-full border-0 gap-0.5 ">
          <SIconButton
            size="sm"
            aria-label="back"
            disabled={!cursor.prev}
            icon={ArrowLeftIcon}
            key="Back"
            onClick={() => {
              onChange?.({
                pageSize,
                current: pageIndex,
                nextLink: cursor.prev,
              })
            }}
          />
          <SIconButton
            size="sm"
            aria-label="next"
            disabled={!cursor.next}
            icon={ArrowRightIcon}
            key="next"
            onClick={() => {
              onChange?.({
                pageSize,
                current: pageIndex,
                nextLink: cursor.next,
              })
            }}
          />
        </ButtonGroup>
      )}

      {!cursor && (
        <>
          <p className="text-sm font-semibold px-2">
            Page {pageIndex + 1} of {totalPages}
          </p>

          <nav
            aria-label="Pagination"
            className="isolate inline-flex  rounded-md shadow-sm"
          >
            <ButtonGroup className="dark:bg-black/20 bg-white/20 rounded-full border-0">
              {getNumbers.map((option) => {
                if (option === LEFT_PAGE)
                  return (
                    <SIconButton
                      size="sm"
                      aria-label="back"
                      disabled={!canPreviousPage}
                      icon={ChevronLeftIcon}
                      onClick={prevPage}
                      key={option}
                      variant="ghost_default"
                      className="!rounded-none aspect-square"
                    />
                  )

                if (option === RIGHT_PAGE)
                  return (
                    <SIconButton
                      size="sm"
                      aria-label="next"
                      disabled={!canNextPage}
                      icon={ChevronRightIcon}
                      onClick={nextPage}
                      key={option}
                      variant="ghost_default"
                      className="!rounded-none aspect-square"
                    />
                  )
                return (
                  <SButton
                    size="sm"
                    key={option}
                    onClick={() => {
                      setPageIndex(option - 1)
                      onPageIndexChange?.(option - 1)
                      onChange?.({
                        pageSize,
                        current: option - 1,
                        nextLink: '',
                      })
                    }}
                    isActive={pageIndex === option - 1}
                    variant="ghost_default"
                    className="aspect-square w-8"
                  >
                    {option}
                  </SButton>
                )
              })}
            </ButtonGroup>
          </nav>
        </>
      )}

      {Boolean(showSizeChanger) && (
        <Select
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          value={pageSize}
          variant="flushed"
          className="rounded-full"
          size="sm"
        >
          {rowsPerPageOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      )}
    </div>
  )
}

export default Pagination
